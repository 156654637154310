import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import BasicLayout from "../components/BasicLayout";
import logo from "../../../assets/images/brand.png";
import images from "../../../assets/images/illustrations/login.svg";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaKey } from "proxy";
import { message, Spin } from "antd";
import { useState } from "react";
import { login } from "utils/apis";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import MetaData from "layouts/MetaData/MetaData";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="#">
        Cody.tn
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function SignInSide() {
  const [captchaValidated, setIsCaptchaValidated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const navigate = useNavigate();

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate inputs
    let valid = true;
    if (!email) {
      setEmailError("Email est requis");
      valid = false;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Mot de passe est requis");
      valid = false;
    } else {
      setPasswordError("");
    }

    if (!valid) return;

    const success = await login(email, password);
    if (success) {
      message.success("Connexion effectuée avec succées");
      navigate("/projects");
    } else {
      message.error("Échec de la connexion. Veuillez vérifier vos identifiants");
    }
  };

  const isValid = React.useMemo(() => {
    return (
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) && password.length > 6
    );
  }, [email, password]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: "white",
          zIndex: 9999,
        }}
      >
        <Spin
          indicator={<LoadingOutlined spin />}
          size="large"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        />
      </div>
    );
  }

  return (
    <>
      <MetaData title="Fidel - Connexion" />
      <BasicLayout>
        <ThemeProvider theme={defaultTheme}>
          <Grid container component="main" sx={{ height: "100vh" }}>
            <CssBaseline />
            <Grid
              item
              xs={false}
              sm={4}
              md={7}
              sx={{
                backgroundImage: `url(${images})`,
                backgroundRepeat: "no-repeat",
                backgroundColor: (t) =>
                  t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
                backgroundSize: "content",
                backgroundPosition: "center",
                transition: "background-image 1s ease-out",
              }}
            />
            <Grid
              item
              xs={12}
              sm={8}
              md={5}
              component={Paper}
              elevation={3}
              square
              sx={{ backgroundColor: "#F0F0F6" }}
            >
              <Box
                sx={{
                  my: 8,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={logo} style={{ width: "100px" }} alt="logo-fidel" />

                <Typography component="h1" variant="h5" sx={{ fontWeight: "500", mt: 2 }}>
                  Accédez à votre monde en un clic
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 10 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    placeholder="Entrer votre email"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!!emailError}
                    helperText={emailError}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Mot de passe"
                    type="password"
                    id="password"
                    placeholder="Entrer votre mot de passe"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={!!passwordError}
                    helperText={passwordError}
                  />
                  <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                    <ReCAPTCHA
                      sitekey={recaptchaKey}
                      onChange={(c) =>
                        c ? setIsCaptchaValidated(true) : setIsCaptchaValidated(false)
                      }
                    />
                  </Box>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="warning"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={!captchaValidated || !isValid}
                  >
                    Connexion
                  </Button>
                </Box>
              </Box>
              <Copyright sx={{ mt: 15 }} />
            </Grid>
          </Grid>
        </ThemeProvider>
      </BasicLayout>
    </>
  );
}
