import React, { useState, useEffect } from "react";
import { Input, Select, DatePicker, Typography } from "antd";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import { bailleurs, codeDevise, naturededepense } from "./data/data";
import { Box, Button, Grid, Modal } from "@mui/material";
import MDTypography from "components/MDTypography";
import "dayjs/locale/fr";
import locale from "antd/es/date-picker/locale/fr_FR";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

dayjs.locale("fr");

const { Option } = Select;
const { Title } = Typography;

const style = {
  position: "absolute",
  minHeight: "40vh",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  background: "white",
  boxShadow: 24,
  padding: 40,
};

const EditModal = ({ visible, data, handleCancel, handleSave }) => {
  // Extract the data properties
  const [newRow, setNewRow] = useState({ ...data });

  const [dataExcel, setDataExcel] = useState([]);
  const [fileName, setFileName] = useState("");

  // Set initial values
  useEffect(() => {
    setNewRow({ ...data });
  }, [data]);

  useEffect(() => {
    const excelData = localStorage.getItem("excelData");
    if (excelData) {
      setDataExcel(JSON.parse(excelData));
    }
  }, []);

  const handleInputChange = (field, value) => {
    setNewRow({ ...newRow, [field]: value });
  };

  const handleInputChangeDevise = (field, e) => {
    const value = e.target.value;
    setNewRow((prevRow) => {
      const updatedRow = { ...prevRow, [field]: value };

      if (field === "devise" || field === "taux") {
        const devise = field === "devise" ? value : prevRow.devise;
        const taux = field === "taux" ? value : prevRow.taux;
        if (devise && taux && !isNaN(devise) && !isNaN(taux)) {
          updatedRow.total = (devise / taux).toFixed(2);
        }
      }

      return updatedRow;
    });
  };

  const onChangeDate = (date, dateString) => {
    const parsedDate = dayjs(dateString, "YYYY-MMM-DD");
    if (parsedDate.isValid()) {
      setNewRow((prevState) => {
        return { ...prevState, date: dateString };
      });
    } else {
      console.error("Invalid date object received.");
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setNewRow((prevState) => ({
          ...prevState,
          file: reader.result,
        }));
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
    }
  };

  // Submit data to parent component
  const handleFormSubmit = () => {
    handleSave(newRow);
  };

  return (
    <Modal open={visible} onClose={handleCancel}>
      <Box style={style}>
        <MDTypography variant="h5">Modifier la dépense</MDTypography>
        <div style={{ minHeight: "400px", overflow: "auto" }}>
          <form style={{ marginTop: "20px" }}>
            <MDBox py={2}>
              <Grid container spacing={3} py={1} style={{ display: "flex", alignItems: "center" }}>
                <Grid item xs={3}>
                  <Title level={5}>Date de dépense</Title>
                  <DatePicker
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                    defaultValue={dayjs(new Date())}
                    locale={locale}
                    dropdownStyle={{ zIndex: 9999999 }}
                    value={newRow.date ? dayjs(newRow.date, "YYYY-MMM-DD") : null}
                    format="YYYY-MMM-DD"
                    onChange={onChangeDate}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography.Title level={5}>Ligne budgétaire</Typography.Title>
                  <Select
                    dropdownStyle={{ zIndex: 9999999 }}
                    value={newRow.ligne}
                    style={{ width: "100%" }}
                    onChange={(e) => handleInputChange("ligne", e)}
                  >
                    <Option value="">Sélectionner une option</Option>
                    {dataExcel.map((item, index) => (
                      <Option key={index} value={item.Coûts}>
                        {item.Coûts}
                      </Option>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <Title level={5}>Objet de la dépense</Title>
                  <Input
                    width={"100%"}
                    type="text"
                    value={newRow.objet}
                    onChange={(e) => handleInputChange("objet", e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Title level={5}>Tiers</Title>
                  <Input
                    width={"100%"}
                    type="text"
                    value={newRow.tiers}
                    onChange={(e) => handleInputChange("tiers", e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Title level={5}>Nature de dépense</Title>
                  <Select
                    dropdownStyle={{ zIndex: 9999999 }}
                    value={newRow.nature}
                    style={{ width: "100%" }}
                    onChange={(e) => handleInputChange("nature", e)}
                  >
                    <Option value="">Sélectionner une option</Option>
                    {naturededepense.map((item) => (
                      <Option key={item.value} value={item.label}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <Title level={5}>Nbr unité</Title>
                  <Input
                    width={"100%"}
                    type="number"
                    value={newRow.nbr}
                    onChange={(e) => handleInputChange("nbr", e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Title level={5}>Montant unitaire en devise locale</Title>
                  <Input
                    width={"100%"}
                    type="number"
                    value={newRow.devise}
                    onChange={(e) => handleInputChangeDevise("devise", e)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Title level={5}>Code devise</Title>
                  <Select
                    dropdownStyle={{ zIndex: 9999999 }}
                    value={newRow.code}
                    style={{ width: "100%" }}
                    onChange={(e) => handleInputChange("code", e)}
                  >
                    <Option value="">Sélectionner une option</Option>
                    {codeDevise.map((item) => (
                      <Option key={item.value} value={item.label}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <Title level={5}>Taux de change</Title>
                  <Input
                    width={"100%"}
                    type="number"
                    value={newRow.taux}
                    onChange={(e) => handleInputChangeDevise("taux", e)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Title level={5}>Total</Title>
                  <Input
                    width={"100%"}
                    type="number"
                    value={newRow.total}
                    onChange={(e) => handleInputChange("total", e)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Title level={5}>N° RF bailleur</Title>
                  <Select
                    dropdownStyle={{ zIndex: 9999999 }}
                    value={newRow.bailleur}
                    style={{ width: "100%" }}
                    onChange={(e) => handleInputChange("bailleur", e)}
                  >
                    <Option value="">Sélectionner une option</Option>
                    {bailleurs.map((item) => (
                      <Option key={item.value} value={item.label}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={3}>
                  <Title level={5}>N° Pièce comptable</Title>
                  <Input
                    width={"100%"}
                    type="text"
                    value={newRow.piece}
                    onChange={(e) => handleInputChange("piece", e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Title level={5}>Montant refacturable</Title>
                  <Input
                    width={"100%"}
                    type="text"
                    value={newRow.refacturable}
                    onChange={(e) => handleInputChange("refacturable", e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Title level={5}>Commentaire SUB</Title>
                  <Input
                    width={"100%"}
                    type="text"
                    value={newRow.commentaire}
                    onChange={(e) => handleInputChange("commentaire", e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography.Title level={5}>Justificatif</Typography.Title>
                  <input
                    accept=".pdf,.jpg,.jpeg"
                    style={{ display: "none" }}
                    id="contained-button-file"
                    type="file"
                    onChange={handleFileUpload}
                    name="file"
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      component="span"
                      startIcon={<CloudUploadIcon />}
                      style={{
                        width: "100%",
                        textTransform: "none",
                        padding: "10px 20px",
                      }}
                    >
                      {fileName || "Importer une image ou un pdf"}
                    </Button>
                  </label>
                </Grid>
              </Grid>
            </MDBox>
          </form>
        </div>
        <Box display="flex" justifyContent="center" mt="20px">
          <Button
            onClick={() => {
              handleFormSubmit();
              handleCancel();
            }}
            type="submit"
            color="primary"
            variant="contained"
          >
            <span style={{ color: "white" }}>Modifier</span>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

EditModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default EditModal;
