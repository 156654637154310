export const naturededepense = [
  { value: "contribution", label: "Contribution bailleur" },
  { value: "financement", label: "Co-financement" },
  { value: "autre", label: "Autre" },
];

export const codeDevise = [
  { value: "EU", label: "Euro" },
  { value: "Dollar", label: "Dollar" },
  { value: "Dinar", label: "Dinar" },
];

export const bailleurs = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
];
