import { Box, Button, Checkbox, FormControlLabel, Grid, Modal } from "@mui/material";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form, Input } from "antd";
import MDBox from "components/MDBox";
import { formItemStyle } from "components/styles";

const style = {
  position: "absolute",
  minHeight: "40vh",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  background: "white",
  boxShadow: 24,
  padding: 40,
};

const EditUser = ({ data, visible, handleClose, handleEditUser }) => {
  const [form] = Form.useForm();

  const [user, setUser] = useState(data);

  const handlePermissionChange = (event, category) => {
    const { name, checked } = event.target;
    setUser((prevUser) => ({
      ...prevUser,
      [category]: {
        ...prevUser[category],
        [name]: checked,
      },
    }));
  };

  const handleRoleChange = (event) => {
    setUser({ ...user, roles: [event.target.value] });
  };

  const handleSubmit = () => {
    handleEditUser(user);
  };
  useEffect(() => {}, [data]);

  return (
    <>
      <Modal open={visible} onClose={handleClose}>
        <Box style={style}>
          <MDTypography variant="h5">Modifier utilisateur</MDTypography>
          <div>
            <form style={{ marginTop: "20px" }}>
              <Form size={"large"} layout={"vertical"} form={form}>
                <MDBox py={2}>
                  <Grid
                    container
                    spacing={3}
                    py={1}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid item xs={4}>
                      <Form.Item
                        style={formItemStyle}
                        label="Nom"
                        name={"nom"}
                        initialValue={user.firstname}
                        onChange={(e) => setUser({ ...user, nom: e.target.value })}
                      >
                        <Input type="text" style={{ fontSize: "16px" }} />
                      </Form.Item>
                    </Grid>
                    <Grid item xs={4}>
                      <Form.Item
                        style={formItemStyle}
                        label="Prénom"
                        name={"prenom"}
                        initialValue={user.lastname}
                        onChange={(e) => setUser({ ...user, prenom: e.target.value })}
                      >
                        <Input type="text" style={{ fontSize: "16px" }} />
                      </Form.Item>
                    </Grid>
                    <Grid item xs={4}>
                      <Form.Item
                        style={formItemStyle}
                        label="Email"
                        name={"email"}
                        initialValue={user.email}
                        onChange={(e) => setUser({ ...user, email: e.target.value })}
                      >
                        <Input disabled type="email" style={{ fontSize: "16px" }} />
                      </Form.Item>
                    </Grid>
                    <Grid item xs={4}>
                      <Form.Item
                        style={formItemStyle}
                        label="Téléphone"
                        name={"telephone"}
                        initialValue={user.telephone}
                        onChange={(e) => setUser({ ...user, telephone: e.target.value })}
                      >
                        <Input type="text" style={{ fontSize: "16px" }} />
                      </Form.Item>
                    </Grid>
                    <Grid item xs={5}>
                      <Form.Item
                        rules={[{ required: true, message: "Champ obligatoire" }]}
                        style={formItemStyle}
                        label="Poste"
                        name={"poste"}
                        initialValue={user.roles}
                        onChange={handleRoleChange}
                      >
                        <Input type="text" style={{ fontSize: "16px" }} />
                      </Form.Item>
                    </Grid>
                    <Grid item xs={4}>
                      <MDTypography variant="caption" color="text" fontWeight="medium">
                        Permissions Project
                      </MDTypography>

                      <FormControlLabel
                        control={
                          <Checkbox
                            initialValue={user?.access[0]?.project?.lire}
                            checked={user?.access[0]?.project?.lire}
                            onChange={(e) => handlePermissionChange(e, "project")}
                            name="lire"
                          />
                        }
                        label="Lire"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            initialValue={user?.access[0]?.project?.ecrire}
                            checked={user?.access[0]?.project?.ecrire}
                            onChange={(e) => handlePermissionChange(e, "project")}
                            name="ecrire"
                          />
                        }
                        label="Ecrire"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            initialValue={user?.access[0]?.project?.modifier}
                            checked={user?.access[0]?.project?.modifier}
                            onChange={(e) => handlePermissionChange(e, "project")}
                            name="modifier"
                          />
                        }
                        label="Modifier"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <MDTypography
                        variant="caption"
                        color="text"
                        fontWeight="medium"
                        sx={{ gridColumn: "span 4" }}
                      >
                        Permissions user
                      </MDTypography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            initialValue={user?.access[0]?.user?.lire}
                            checked={user?.access[0]?.user?.lire}
                            onChange={(e) => handlePermissionChange(e, "user")}
                            name="lire"
                          />
                        }
                        label="Lire"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            initialValue={user?.access[0]?.user?.ecrire}
                            checked={user?.access[0]?.user?.ecrire}
                            onChange={(e) => handlePermissionChange(e, "user")}
                            name="ecrire"
                          />
                        }
                        label="Ecrire"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            initialValue={user?.access[0]?.user?.supprimer}
                            checked={user?.access[0]?.user?.supprimer}
                            onChange={(e) => handlePermissionChange(e, "user")}
                            name="supprimer"
                          />
                        }
                        label="Supprimer"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            initialValue={user?.access[0]?.user?.modifier}
                            checked={user?.access[0]?.user?.modifier}
                            onChange={(e) => handlePermissionChange(e, "user")}
                            name="modifier"
                          />
                        }
                        label="Modifier"
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              </Form>
            </form>
          </div>
          <Box display="flex" justifyContent="center" mt="20px">
            <Button
              onClick={() => {
                handleSubmit();
                handleClose();
              }}
              type="submit"
              color="primary"
              variant="contained"
            >
              <span style={{ color: "white" }}>Modifier</span>
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

EditUser.propTypes = {
  data: PropTypes.object,
  handleClose: PropTypes.func,
  visible: PropTypes.bool,
  handleEditUser: PropTypes.func,
};

export default EditUser;
