import React, { useEffect, useState } from "react";
import breakpoints from "assets/theme/base/breakpoints";
import PropTypes from "prop-types";
import { AppBar, Box, Icon, Tab, Tabs } from "@mui/material";
import TableFinance from "layouts/TableFinance/TableFinance";
import InfoGenerale from "./InfoGenerale";
import BudgetDetails from "./BudgetDetails";
import MDTabs from "components/MDTabs/Tabs";
import MDButton from "components/MDButton";
import { toGetListOfProjectDetails } from "utils/apis";

const ProjectDetails = ({ projectId, setProjectId }) => {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const fetchData = async () => {
    const projects = await toGetListOfProjectDetails({ id: projectId });
    setData(projects);
    setLoading(false);
  };

  return (
    <>
      <MDButton
        variant="gradient"
        color="info"
        size="medium"
        onClick={() => setProjectId(null)}
        sx={{ mb: 3 }}
      >
        <Icon fontSize="small">arrow_back</Icon>
        Retour à la liste des projets
      </MDButton>
      <Box>
        <AppBar position="static">
          <Tabs
            indicatorColor="primary"
            orientation={tabsOrientation}
            value={tabValue}
            onChange={handleSetTabValue}
          >
            <Tab
              label="Informations sur le projet"
              icon={
                <Icon fontSize="small" sx={{ mt: -0.25 }}>
                  information
                </Icon>
              }
            />
            <Tab
              label="Sheets"
              icon={
                <Icon fontSize="small" sx={{ mt: -0.25 }}>
                  table_view
                </Icon>
              }
            />
            <Tab
              label="Budget"
              icon={
                <Icon fontSize="small" sx={{ mt: -0.25 }}>
                  currency_bitcoin_outlined
                </Icon>
              }
            />
            <Tab
              label="Dépense"
              icon={
                <Icon fontSize="small" sx={{ mt: -0.25 }}>
                  account_balance_wallet
                </Icon>
              }
            />
          </Tabs>
        </AppBar>
        {tabValue === 0 ? (
          <>
            <InfoGenerale projectId={projectId} data={data} loading={loading} />
          </>
        ) : tabValue === 1 ? (
          <>
            <MDTabs projectId={projectId} />
          </>
        ) : tabValue === 2 ? (
          <>
            <BudgetDetails projectId={projectId} data={data} loading={loading} />
          </>
        ) : (
          <>
            <TableFinance projectId={projectId} data={data} loading={loading} />
          </>
        )}
      </Box>
    </>
  );
};

ProjectDetails.propTypes = {
  projectId: PropTypes.string,
  setProjectId: PropTypes.func,
};
export default ProjectDetails;
