import { Button, Card, Grid, Icon, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import TableDepense from "./Table";
import BasicModal from "components/MDModal/Modal";
import { Input, Select, Typography, DatePicker, message } from "antd";
import { bailleurs, codeDevise, naturededepense } from "./data/data";
import dayjs from "dayjs";

import "dayjs/locale/fr";
import locale from "antd/es/date-picker/locale/fr_FR";
import PropTypes from "prop-types";
import { toUpdateProject } from "utils/apis";
import { toGetListOfProjectDetails } from "utils/apis";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

dayjs.locale("fr");

const { Option } = Select;

const TableFinance = ({ projectId, data }) => {
  const [dataTable, setDataTable] = useState([]);
  const [projectData, setProjectData] = useState(null);
  const [fileName, setFileName] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [newRow, setNewRow] = useState({
    id: Math.random().toString(32).substring(2, 15) + Math.random().toString(36).substring(2, 15),
    date: dayjs().format("YYYY-MMM-DD"),
    ligne: "",
    objet: "",
    tiers: "",
    nature: "",
    nbr: "",
    devise: "",
    code: "",
    taux: "",
    total: "",
    bailleur: "",
    eligible: "",
    piece: "",
    refacturable: "",
    commentaire: "",
    file: "",
  });

  const filteredData =
    projectData && projectData.finance.budget.filter((item) => /^\s*\d/.test(item.Coûts));

  useEffect(() => {
    const fetchData = async () => {
      const projects = await toGetListOfProjectDetails({ id: projectId });
      if (projects) {
        setProjectData(projects);
      }
    };
    fetchData();
  }, [projectId, dataTable]);

  const handleInputChange = (field, value) => {
    setNewRow({ ...newRow, [field]: value });
  };

  const handleInputChangeDevise = (field, e) => {
    const value = e.target.value;
    setNewRow((prevRow) => {
      const updatedRow = { ...prevRow, [field]: value };

      if (field === "devise" || field === "taux") {
        const devise = field === "devise" ? value : prevRow.devise;
        const taux = field === "taux" ? value : prevRow.taux;
        if (devise && taux && !isNaN(devise) && !isNaN(taux)) {
          updatedRow.total = (devise / taux).toFixed(2);
        }
      }

      return updatedRow;
    });
  };

  const onChangeDate = (date, dateString) => {
    const parsedDate = dayjs(dateString, "YYYY-MMM-DD");
    if (parsedDate.isValid()) {
      setNewRow((prevState) => {
        return { ...prevState, date: dateString };
      });
    } else {
      console.error("Invalid date object received.");
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setNewRow((prevState) => ({
          ...prevState,
          file: reader.result,
        }));
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
    }
  };

  const handleSubmit = async () => {
    const serializedData = { ...newRow };

    const updatedProject = {
      ...projectData,
      finance: {
        ...projectData.finance,
        depenses: [...projectData.finance.depenses, serializedData],
      },
    };

    const success = await toUpdateProject({ id: data.id, data: updatedProject });
    const updatedProjectDetails = await toGetListOfProjectDetails({ id: projectId });
    setDataTable({ ...updatedProjectDetails.finance.depenses });

    if (success) {
      message.success("Dépense ajoutée avec succès");
    } else {
      message.error("Echec de l'ajout de projet");
    }

    setNewRow({
      id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
      date: dayjs().format("YYYY-MMM-DD"),
      ligne: "",
      objet: "",
      tiers: "",
      nature: "",
      nbr: "",
      devise: "",
      code: "",
      taux: "",
      total: "",
      bailleur: "",
      eligible: "",
      piece: "",
      refacturable: "",
      commentaire: "",
      file: "",
    });

    setOpen(false);
  };

  return (
    <>
      <MDBox mt={2} pt={4} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Journal des dépenses
                </MDTypography>
                <BasicModal
                  btn="Enregistrer"
                  titleModal="Ajouter dépense"
                  titleBtn={
                    <IconButton color={"white"}>
                      <Icon fontSize="large">add_circle_outline</Icon>
                    </IconButton>
                  }
                  open={open}
                  handleClose={handleClose}
                  handleOpen={handleOpen}
                  handleSubmit={handleSubmit}
                >
                  <div style={{ minHeight: "400px", overflow: "auto" }}>
                    <form style={{ marginTop: "20px" }}>
                      <MDBox py={2}>
                        <Grid
                          container
                          spacing={3}
                          py={1}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Grid item xs={3}>
                            <Typography.Title level={5}>Date de dépense</Typography.Title>
                            <DatePicker
                              getPopupContainer={(triggerNode) => {
                                return triggerNode.parentNode;
                              }}
                              defaultValue={dayjs(new Date())}
                              locale={locale}
                              dropdownStyle={{ zIndex: 9999999 }}
                              value={newRow.date ? dayjs(newRow.date, "YYYY-MMM-DD") : null}
                              format="YYYY-MMM-DD"
                              onChange={onChangeDate}
                              style={{ width: "100%" }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Typography.Title level={5}>Ligne budgétaire</Typography.Title>
                            <Select
                              dropdownStyle={{ zIndex: 9999999 }}
                              value={newRow.ligne}
                              style={{ width: "100%" }}
                              onChange={(e) => handleInputChange("ligne", e)}
                            >
                              <Option value="">Sélectionner une option</Option>
                              {filteredData &&
                                filteredData.map((item, index) => (
                                  <Option key={index} value={item.Coûts}>
                                    {item.Coûts}
                                  </Option>
                                ))}
                            </Select>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography.Title level={5}>Objet de la dépense</Typography.Title>
                            <Input
                              width={"100%"}
                              type="text"
                              value={newRow.objet}
                              onChange={(e) => handleInputChange("objet", e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Typography.Title level={5}>Tiers</Typography.Title>
                            <Input
                              width={"100%"}
                              type="text"
                              value={newRow.tiers}
                              onChange={(e) => handleInputChange("tiers", e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Typography.Title level={5}>Nature de dépense</Typography.Title>
                            <Select
                              dropdownStyle={{ zIndex: 9999999 }}
                              value={newRow.nature}
                              style={{ width: "100%" }}
                              onChange={(e) => handleInputChange("nature", e)}
                            >
                              <Option value="">Sélectionner une option</Option>
                              {naturededepense.map((item) => (
                                <Option key={item.value} value={item.label}>
                                  {item.label}
                                </Option>
                              ))}
                            </Select>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography.Title level={5}>Nbr unité</Typography.Title>
                            <Input
                              width={"100%"}
                              type="number"
                              value={newRow.nbr}
                              onChange={(e) => handleInputChange("nbr", e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Typography.Title level={5}>
                              Montant unitaire en devise locale
                            </Typography.Title>
                            <Input
                              width={"100%"}
                              type="number"
                              value={newRow.devise}
                              onChange={(e) => handleInputChangeDevise("devise", e)}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Typography.Title level={5}>Code devise</Typography.Title>
                            <Select
                              dropdownStyle={{ zIndex: 9999999 }}
                              value={newRow.code}
                              style={{ width: "100%" }}
                              onChange={(e) => handleInputChange("code", e)}
                            >
                              <Option value="">Sélectionner une option</Option>
                              {codeDevise.map((item) => (
                                <Option key={item.value} value={item.label}>
                                  {item.label}
                                </Option>
                              ))}
                            </Select>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography.Title level={5}>Taux de change</Typography.Title>
                            <Input
                              width={"100%"}
                              type="number"
                              value={newRow.taux}
                              onChange={(e) => handleInputChangeDevise("taux", e)}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Typography.Title level={5}>Total</Typography.Title>
                            <Input
                              width={"100%"}
                              type="number"
                              value={newRow.total}
                              onChange={(e) => handleInputChange("total", e)}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Typography.Title level={5}>Année</Typography.Title>
                            <Select
                              dropdownStyle={{ zIndex: 9999999 }}
                              value={newRow.bailleur}
                              style={{ width: "100%" }}
                              onChange={(e) => handleInputChange("bailleur", e)}
                            >
                              <Option value="">Sélectionner une option</Option>
                              {bailleurs.map((item) => (
                                <Option key={item.value} value={item.label}>
                                  {item.label}
                                </Option>
                              ))}
                            </Select>
                          </Grid>

                          <Grid item xs={3}>
                            <Typography.Title level={5}>N° Pièce comptable</Typography.Title>
                            <Input
                              width={"100%"}
                              type="text"
                              value={newRow.piece}
                              onChange={(e) => handleInputChange("piece", e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Typography.Title level={5}>Montant refacturable</Typography.Title>
                            <Input
                              width={"100%"}
                              type="text"
                              value={newRow.refacturable}
                              onChange={(e) => handleInputChange("refacturable", e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Typography.Title level={5}>Commentaire SUB</Typography.Title>
                            <Input
                              width={"100%"}
                              type="text"
                              value={newRow.commentaire}
                              onChange={(e) => handleInputChange("commentaire", e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Typography.Title level={5}>Justificatif</Typography.Title>
                            <input
                              accept=".pdf,.jpg,.jpeg"
                              style={{ display: "none" }}
                              id="contained-button-file"
                              type="file"
                              onChange={handleFileUpload}
                            />
                            <label htmlFor="contained-button-file">
                              <Button
                                component="span"
                                startIcon={<CloudUploadIcon />}
                                style={{
                                  width: "100%",
                                  textTransform: "none",
                                  padding: "10px 20px",
                                }}
                              >
                                {fileName || "Importer une image ou un pdf"}
                              </Button>
                            </label>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </form>
                  </div>
                </BasicModal>
              </MDBox>

              <MDBox pt={2}>
                <TableDepense
                  projectId={projectId}
                  dataTable={dataTable}
                  setDataTable={setDataTable}
                  data={data}
                  filteredData={filteredData}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
};

TableFinance.propTypes = {
  projectId: PropTypes.string,
  data: PropTypes.object,
  loading: PropTypes.bool,
};

export default TableFinance;
