import React, { useEffect, useState } from "react";
import { Popconfirm, Spin, Table, message } from "antd";
import "./style.css";
import PropTypes from "prop-types";
import EditModal from "./EditTable";
import { Icon } from "@mui/material";
import depense from "../../assets/images/illustrations/despense.svg";
import { toUpdateProject } from "utils/apis";
import { toGetListOfProjectDetails } from "utils/apis";
import MDButton from "components/MDButton";
import MetaData from "layouts/MetaData/MetaData";

const EmptyState = () => (
  <div style={{ textAlign: "center" }}>
    <img style={{ marginTop: 5 }} width={200} src={depense} alt="despense" />
    <p style={{ marginTop: 20, color: "#909090" }}>Pas de dépenses</p>
  </div>
);

const TableDepense = ({ projectId, dataTable, setDataTable, data, filteredData }) => {
  const [dataSource, setDataSource] = useState(dataTable || []);
  const [editingRow, setEditingRow] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const project = await toGetListOfProjectDetails({ id: projectId });
      const mappedData = project?.finance?.depenses.map((row) => {
        return row;
      });
      setDataSource(mappedData);
      setLoading(false);
    };
    fetchData();
  }, [projectId, dataTable]);

  const getTotalRestant = (record) => {
    const dataPerLigne = dataSource.filter((el) => el.ligne === record.ligne);
    if (filteredData?.filter((el) => el.Coûts === record.ligne).length === 0) {
      return 0;
    }
    const firstTotal =
      filteredData?.filter((el) => el.Coûts === record.ligne)[0]["Coût total (en EUR)3"] ?? 0;
    const index = dataPerLigne.findIndex((el) => el.id === record.id);
    const list = dataPerLigne.slice(0, index + 1);
    return firstTotal - list.reduce((acc, prev) => parseFloat(prev.total) + parseFloat(acc), 0);
  };

  // Take the row data and set it to the editing state
  const handleEdit = (record) => {
    setEditingRow({ ...record });
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingRow(null);
  };

  // Save the updated row data
  const handleSave = async (updatedRow) => {
    const existingDepenseIndex = dataSource.findIndex((depense) => depense.id === updatedRow.id);

    let newDepenses;

    if (existingDepenseIndex !== -1) {
      newDepenses = dataSource.map((depense, index) =>
        index === existingDepenseIndex ? updatedRow : depense
      );
    } else {
      newDepenses = [...dataSource, updatedRow];
    }

    const updatedProject = {
      ...data,
      finance: {
        ...data.finance,
        depenses: newDepenses,
      },
    };
    setDataSource(newDepenses);
    setDataTable(newDepenses);

    const success = await toUpdateProject({ id: data.id, data: updatedProject });

    if (success) {
      message.success("Dépense editer avec succès");
    } else {
      message.error("Echec de l'edit de projet");
    }
    setIsModalVisible(false);
  };

  // Delete a row
  const handleDelete = async (record) => {
    const updatedDataSource = dataSource.filter((depense) => depense.id !== record.id);

    const updatedProject = {
      ...data,
      finance: {
        ...data.finance,
        depenses: updatedDataSource,
      },
    };
    setDataSource(updatedDataSource);
    setDataTable(updatedDataSource);

    const success = await toUpdateProject({ id: data.id, data: updatedProject });

    if (success) {
      message.success("Dépense supprimée avec succès");
    } else {
      message.error("Echec de la suppression de la dépense");
    }
  };

  const openBase64 = (data) => {
    if (data.includes("pdf")) {
      const w = window.open("");

      if (w) {
        w.document.write(
          `<embed width="100%" height="100%" src="${data}" type="application/pdf" />`
        );
      } else {
        throw new Error("Failed to open a new tab.");
      }
    } else {
      let w = window.open("about:blank");
      let image = new Image();
      image.src = data;
      setTimeout(function () {
        w.document.getElementsByTagName("body")[0].innerHTML = image.outerHTML;
      }, 0);
    }
  };

  const columns = [
    {
      title: "Date de la dépense",
      dataIndex: "date",
      key: "date",
      align: "center",
      filters: Array.from(new Set(dataSource.map((item) => item.date))).map((item) => ({
        text: item,
        value: item,
      })),
      onFilter: (value, record) => record.date.includes(value),
    },
    {
      title: "Ligne budgétaire",
      dataIndex: "ligne",
      key: "ligne",
      align: "center",
      filters: Array.from(new Set(dataSource.map((item) => item.ligne))).map((ligne) => ({
        text: ligne,
        value: ligne,
      })),
      onFilter: (value, record) => record.ligne.includes(value),
    },
    {
      title: "Objet de la dépense",
      dataIndex: "objet",
      key: "objet",
      align: "center",
    },
    {
      title: "Tiers",
      dataIndex: "tiers",
      key: "tiers",
      align: "center",
      filters: Array.from(new Set(dataSource.map((item) => item.tiers))).map((tier) => ({
        text: tier,
        value: tier,
      })),
      onFilter: (value, record) => record.tiers.includes(value),
    },
    {
      title: "Nature de dépense",
      dataIndex: "nature",
      key: "nature",
      align: "center",
    },
    {
      title: "Dépenses",
      children: [
        {
          title: "Montant",
          children: [
            {
              title: "Nbr unité",
              dataIndex: "nbr",
              key: "nbr",
              align: "center",
            },
            {
              title: "Montant unitaire en devise locale",
              dataIndex: "devise",
              key: "devise",
              align: "center",
            },
            {
              title: "Code devise",
              dataIndex: "code",
              key: "code",
              align: "center",
            },
            {
              title: "Taux de change",
              dataIndex: "taux",
              key: "taux",
              align: "center",
            },
            {
              title: "Total",
              dataIndex: "total",
              key: "total",
              align: "center",
            },
          ],
          dataIndex: "montant",
          key: "montant",
          align: "center",
        },
        {
          title: "Année",
          dataIndex: "bailleur",
          key: "bailleur",
          align: "center",
        },

        {
          title: "N° Pièce comptable",
          dataIndex: "piece",
          key: "piece",
          align: "center",
        },
      ],
      dataIndex: "dépense",
      key: "dépense",
    },
    {
      title: "Suivi recettes",
      children: [
        {
          title: "Montant refacturable",
          dataIndex: "refacturable",
          key: "refacturable",
          align: "center",
        },
      ],
    },
    {
      title: "Commentaire SUB",
      dataIndex: "commentaire",
      key: "commentaire",
      align: "center",
    },
    {
      title: "Document",
      dataIndex: "document",
      key: "document",
      align: "center",
      render: (text, record) =>
        record.file != "" &&
        record.file != null && (
          <MDButton onClick={() => openBase64(record.file)}>Afficher</MDButton>
        ),
    },
    {
      title: "Total déspenses restant",
      dataIndex: "restant",
      key: "restant",
      align: "center",
      render: (text, record) => getTotalRestant(record),
    },
    {
      title: "Actions",
      key: "actions",
      align: "center",
      render: (text, record) => (
        <>
          <Icon
            sx={{
              cursor: "pointer",
              marginRight: "10px",
              color: "orange",
              fontSize: "20px !important",
            }}
            onClick={() => handleEdit(record)}
          >
            border_color_rounded
          </Icon>
          <Popconfirm
            title="Supprimer la ligne"
            description="Voulez-vous vraiment supprimer cette ligne?"
            onConfirm={() => handleDelete(record)}
            okText="Oui"
            cancelText="Non"
          >
            <Icon sx={{ cursor: "pointer", color: "#FF3333", fontSize: "20px !important" }}>
              delete_sweep
            </Icon>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <MetaData title="Fidel - Dépenses" />

      <div className="table-container">
        <Table
          locale={{
            emptyText: loading ? <Spin /> : <EmptyState />,
          }}
          dataSource={dataSource}
          columns={columns}
          bordered
          pagination={false}
        />
      </div>

      {editingRow && (
        <EditModal
          visible={isModalVisible}
          data={editingRow}
          handleCancel={handleCancel}
          handleSave={handleSave}
        />
      )}
    </>
  );
};

TableDepense.propTypes = {
  title: PropTypes.string,
  editable: PropTypes.bool,
  children: PropTypes.node,
  dataIndex: PropTypes.string,
  record: PropTypes.object,
  projectId: PropTypes.string,
  dataTable: PropTypes.array,
  setDataTable: PropTypes.func,
  data: PropTypes.object,
  depenseData: PropTypes.object,
  filteredData: PropTypes.array,
};

export default TableDepense;
