import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import MDButton from "components/MDButton";
import { message } from "antd";
import { toAddProject } from "utils/apis";

const Budget = ({ handleClose, projectData, updateProject }) => {
  const [excelData, setExcelData] = useState([]);
  const [headers, setHeaders] = useState([]);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryString = e.target.result;
      const workbook = XLSX.read(binaryString, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const trimmedHeaders = data[0].map((header) => header.replace(/\s+/g, " "));

      data.shift();

      const jsonData = data.map((row) => {
        const jsonObj = {};
        trimmedHeaders.forEach((header, index) => {
          jsonObj[header] = row[index];
        });
        return jsonObj;
      });

      setHeaders(trimmedHeaders);
      setExcelData(jsonData);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleSave = async () => {
    if (excelData.length === 0) {
      message.warning("Veuillez importer un fichier avant de terminer.");
      return;
    }

    const updatedProjectData = {
      ...projectData,
      data: {
        ...projectData.data,
        finance: {
          ...projectData.data.finance,
          budget: [...(projectData.data.finance?.budget || []), ...excelData],
          headers: [...(projectData.data.finance?.headers || []), ...headers],
        },
      },
    };

    const success = await toAddProject(updatedProjectData);
    if (success) {
      message.success("Projet ajouté avec succès");
      updateProject();
    } else {
      message.error("Échec d'ajouter le projet. Veuillez réessayer");
    }

    handleClose();
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <>
      <div>
        <div {...getRootProps()} style={dropzoneStyle}>
          <input {...getInputProps({ multiple: false })} />
          <p>Veuillez glisser un fichier Excel ici ou cliquer pour sélectionner</p>
        </div>
        <div style={{ marginTop: "20px" }}>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <thead>
              <tr style={{ background: "#f2f2f2" }}>
                {headers.map((header, index) => (
                  <th
                    key={index}
                    style={{ padding: "8px", border: "1px solid #ddd", fontSize: "18px" }}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {excelData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {headers.map((header, colIndex) => (
                    <td
                      key={colIndex}
                      style={{ padding: "8px", border: "1px solid #ddd", fontSize: "14px" }}
                    >
                      {row[header]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
        <MDButton variant="gradient" color="info" onClick={handleSave}>
          Terminer
        </MDButton>
      </div>
    </>
  );
};

const dropzoneStyle = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
  marginTop: "20px",
};

Budget.propTypes = {
  handleClose: PropTypes.func,
  setProjectData: PropTypes.func,
  projectData: PropTypes.object,
  updateProject: PropTypes.func,
};

export default Budget;
