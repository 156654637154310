/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import MDTypography from "components/MDTypography";
import SelectForm from "components/MDModal/SelectForm";
import { stade } from "components/MDModal/data";
import MetaData from "layouts/MetaData/MetaData";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;

  return (
    <DashboardLayout>
      <MetaData title="Fidel - Dashboard" />
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3} justifyContent="space-around">
          <Grid item xs={12} md={6} lg={3}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Select projet
            </MDTypography>
            <SelectForm
              label="Select projet"
              options={stade}
              // value={selectedStade}
              // onChange={(e) => setSelectedStade(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox display="flex" justifyContent="space-between">
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                Starting date
              </MDTypography>
              <MDTypography variant="h6" fontWeight="light" textTransform="capitalize">
                29/04/2024
              </MDTypography>
            </MDBox>
            <MDBox display="flex" justifyContent="space-between">
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                Ending date
              </MDTypography>
              <MDTypography variant="h6" fontWeight="light" textTransform="capitalize">
                29/04/2024
              </MDTypography>
            </MDBox>
            <MDBox display="flex" justifyContent="space-between">
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                Days remaining
              </MDTypography>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                -1215
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox py={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={6} xl={3}>
            <DefaultInfoCard
              color="warning"
              icon="library_add_check"
              title="Results status"
              value="23%"
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <DefaultInfoCard
              color="success"
              icon="account_balance_wallet"
              title="Financial status"
              value="22%"
            />
          </Grid>
        </Grid>
      </MDBox>

      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="school"
                title="Formations"
                count={5}
                percentage={{
                  color: "success",
                  label: "Formation",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="wc"
                title="Nombre Homme/Femme"
                count="76/43"
                percentage={{
                  color: "success",
                  label: "Sexe",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="group"
                title="Nombre Sélectionées"
                count="119"
                percentage={{
                  color: "success",
                  label: "Sélectionées",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Nombre Candidats"
                count="154"
                percentage={{
                  color: "success",
                  label: "Candidats présents le jour de l'entretien",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>

        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={12}>
              <Projects title="Expenses" />
            </Grid>
            {/* <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid> */}
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
