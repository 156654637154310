import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { FloatButton, Spin } from "antd";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import ProjectModal from "./ProjectModal";
import ProjectModel from "./ProjectModel";
import ProjectDetails from "./ProjectDetails";
import { toGetListOfProject } from "utils/apis";
import { LoadingOutlined } from "@ant-design/icons";
import StatusIndicators from "./StatusIndicators";
import moment from "moment";
import MetaData from "layouts/MetaData/MetaData";

const Projects = () => {
  const [open, setOpen] = React.useState(false);
  const [projectId, setProjectId] = React.useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const getColor = (status) => {
    switch (status) {
      case "end":
        return "success";
      case "waiting":
        return "warning";
      case "depass":
        return "error";
      case "inprogress":
        return "info";

      default:
        break;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const projects = await toGetListOfProject();
    setData(projects);
    setLoading(false);
  };

  const getDescription = (el) => {
    let diff = "";
    switch (el.status) {
      case "end":
        return "Fini ";
      case "waiting":
        diff = moment(el.dateStart).diff(moment(), "days") + 1;

        return `Commence dans: ${diff} jour(s)`;
      case "depass":
        return "error";
      case "inprogress":
        diff = moment(el.dateEnd).diff(moment(), "days") + 1;

        return `${diff} jour(s) restant(s)`;

      default:
        break;
    }
  };

  return (
    <DashboardLayout>
      <MetaData title="Fidel - Liste des Projets" />
      <DashboardNavbar />
      <ProjectModal
        project={new ProjectModel()}
        open={open}
        handleClose={handleClose}
        updateProject={fetchData}
      />

      {loading ? (
        <Spin
          indicator={<LoadingOutlined spin />}
          size="large"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        />
      ) : (
        projectId === null && (
          <>
            <StatusIndicators />
            <MDBox py={3}>
              <Grid container spacing={3}>
                {data &&
                  data?.map((el) => (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={3}
                      key={el.id}
                      onClick={() => setProjectId(el.id)}
                      sx={{ cursor: "pointer" }}
                    >
                      <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                          color={getColor(el.status)}
                          title={el.projectName}
                          dateStart={el.dateStart}
                          dateEnd={el.dateEnd}
                          description={getDescription(el)}
                          percentage={{
                            color: "success",
                            label: `${el.responsables}`,
                          }}
                        />
                      </MDBox>
                    </Grid>
                  ))}
              </Grid>
            </MDBox>
            <FloatButton
              onClick={() => handleOpen()}
              icon={<PlusOutlined />}
              description="Ajouter un projet"
              shape="square"
              style={{
                right: 50,
                width: "150px",
                height: "40px",
                fontSize: "18px",
              }}
            />
          </>
        )
      )}
      {projectId && <ProjectDetails projectId={projectId} setProjectId={setProjectId} />}
    </DashboardLayout>
  );
};

export default Projects;
