import { Tabs, message } from "antd";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React, { useEffect, useRef, useState } from "react";
import TabNameSection from "./TabNameSection";
import InputsSection from "./InputsSection";
import PropTypes from "prop-types";

export default function Project({ sheets, updateSheetsParent, setTabValue, setProjectData }) {
  const [inputVisibility, setInputVisibility] = useState([false]);

  /* Updated initial state */
  const [tabs, setTabs] = useState(sheets ?? []);
  useEffect(() => {
    setTabs(sheets ?? []);
  }, [sheets]);

  /* Add tab name and validate it */
  const handleAddTab = () => {
    const newTabKey = tabs.length;
    const newTab = {
      tabName: "Nouveau sheet",
      key: String(newTabKey),
      inputs: [{ type: "", label: "", options: [], active: "", inactive: "" }],
      data: [],
    };
    setTabs((prevTabs) => [...prevTabs, newTab]);
    setInputVisibility((prevVisibility) => [...prevVisibility, false]);
    setActiveKey(String(newTabKey));
  };

  const handleToggleInputSection = (tabIndex) => {
    setInputVisibility((prev) => {
      const newVisibility = [...prev];
      newVisibility[tabIndex] = !newVisibility[tabIndex];
      return newVisibility;
    });
  };

  const handleTabNameChange = (tabIndex, newName) => {
    const newTabs = [...tabs];
    newTabs[tabIndex].tabName = newName;
    setTabs(newTabs);
  };

  /* Remove tabs */
  const handleRemoveTab = (tabIndex) => {
    const newTabs = tabs.filter((tab, index) => index !== tabIndex);
    const newInputVisibility = inputVisibility.filter((_, index) => index !== tabIndex);
    setTabs(newTabs);
    setInputVisibility(newInputVisibility);
  };

  /* Add input and validate it */
  const handleAddInput = (tabIndex) => {
    const newTabs = [...tabs];
    const lastInput = newTabs[tabIndex].inputs[newTabs[tabIndex].inputs.length - 1];
    if (
      lastInput?.label === "" ||
      lastInput.type === "" ||
      lastInput?.options?.length === 0 ||
      lastInput?.active === "" ||
      lastInput?.inactive === ""
    ) {
      message.warning("Veuillez d'abord remplir les champs");
      return;
    }
    if (lastInput?.options?.length === 1) {
      message.warning("Veuillez ajouter la deuxième option.");
      return;
    }
    const newInput = { type: "", label: "", options: [], active: "", inactive: "" };
    newTabs[tabIndex].inputs.push(newInput);
    setTabs(newTabs);
  };

  /* Add options to select */
  const handleAddOption = (tabIndex, inputIndex, value) => {
    const newTabs = [...tabs];
    if (Array.isArray(value)) {
      newTabs[tabIndex].inputs[inputIndex].options = value;
    } else {
      newTabs[tabIndex].inputs[inputIndex].options.push(value);
    }
    setTabs(newTabs);
  };

  /* Change input type and validate */
  const handleTypeChange = (tabIndex, inputIndex, value) => {
    const newTabs = [...tabs];
    newTabs[tabIndex].inputs[inputIndex].type = value;
    if (["text", "number", "date", "datetime"].includes(value)) {
      newTabs[tabIndex].inputs[inputIndex] = {
        type: value,
        label: newTabs[tabIndex].inputs[inputIndex].label,
      };
    } else if (value === "select") {
      newTabs[tabIndex].inputs[inputIndex] = {
        type: "select",
        label: newTabs[tabIndex].inputs[inputIndex].label,
        options: [],
      };
    } else if (value === "radio") {
      newTabs[tabIndex].inputs[inputIndex] = {
        type: "radio",
        label: newTabs[tabIndex].inputs[inputIndex].label,
        active: "",
        inactive: "",
      };
    }

    setTabs(newTabs);
  };

  const handleInputChange = (tabIndex, inputIndex, field, value) => {
    const newTabs = [...tabs];
    if (field === "label") {
      newTabs[tabIndex].inputs[inputIndex].label = value;
    } else if (field === "active") {
      newTabs[tabIndex].inputs[inputIndex].active = value;
    } else if (field === "inactive") {
      newTabs[tabIndex].inputs[inputIndex].inactive = value;
    }
    setTabs(newTabs);
  };

  /* Remove input */
  const handleRemoveInput = (tabIndex, inputIndex) => {
    const newTabs = [...tabs];

    /* if there is only one input, do nothing */
    if (newTabs[tabIndex].inputs.length === 1) {
      return;
    }

    newTabs[tabIndex].inputs.splice(inputIndex, 1);
    setTabs(newTabs);
  };

  const handleSubmit = () => {
    const hasEmptyTabName = tabs.some((tab) => tab.tabName.trim() === "");
    const hasInvalidInput = tabs.some((tab) =>
      tab.inputs.some((input) => {
        if (input.type === "" || input.label.trim() === "") {
          message.error("Tous les champs doivent être remplis.");
          return true;
        }
        if (input.type === "select" && input.options.length < 2) {
          message.error("Les champs de type 'select' doivent avoir au moins deux options.");
          return true;
        }
        if (
          input.type === "radio" &&
          (input.active.trim() === "" || input.inactive.trim() === "")
        ) {
          message.error(
            "Les champs de type 'radio' doivent avoir des valeurs actives et inactives."
          );
          return true;
        }
        return false;
      })
    );

    if (hasEmptyTabName) {
      message.error("Le nom de l'onglet ne peut pas être vide.");
      return;
    }

    if (hasInvalidInput) {
      return;
    }

    const updatedTabs = tabs.map((tab, index) => ({ ...tab, key: String(index) }));

    setProjectData((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        sheets: updatedTabs,
      },
    }));

    setTabs([
      {
        tabName: "",
        key: "0",
        inputs: [
          {
            type: "",
            label: "",
            options: [],
            active: "",
            inactive: "",
          },
        ],
      },
    ]);
    setInputVisibility([false]);
    setTabValue(2);
  };

  /* Move tab up */
  const handleMoveTabUp = (tabIndex) => {
    if (tabIndex === 0) return;
    const newTabs = [...tabs];
    const temp = newTabs[tabIndex];
    newTabs[tabIndex] = newTabs[tabIndex - 1];
    newTabs[tabIndex - 1] = temp;
    setTabs(newTabs);
  };

  /* Move tab down */
  const handleMoveTabDown = (tabIndex) => {
    if (tabIndex === tabs.length - 1) return;
    const newTabs = [...tabs];
    const temp = newTabs[tabIndex];
    newTabs[tabIndex] = newTabs[tabIndex + 1];
    newTabs[tabIndex + 1] = temp;
    setTabs(newTabs);
  };

  /* Move input up */
  const handleMoveInputUp = (tabIndex, inputIndex) => {
    if (inputIndex === 0) return; // If it's the first input, cannot move up
    const newTabs = [...tabs];
    const temp = newTabs[tabIndex].inputs[inputIndex];
    newTabs[tabIndex].inputs[inputIndex] = newTabs[tabIndex].inputs[inputIndex - 1];
    newTabs[tabIndex].inputs[inputIndex - 1] = temp;
    setTabs(newTabs);
  };

  /* Move input down */
  const handleMoveInputDown = (tabIndex, inputIndex) => {
    if (inputIndex === tabs[tabIndex].inputs.length - 1) return; // If it's the last input, cannot move down
    const newTabs = [...tabs];
    const temp = newTabs[tabIndex].inputs[inputIndex];
    newTabs[tabIndex].inputs[inputIndex] = newTabs[tabIndex].inputs[inputIndex + 1];
    newTabs[tabIndex].inputs[inputIndex + 1] = temp;
    setTabs(newTabs);
  };

  const [activeKey, setActiveKey] = useState(tabs.length > 0 ? tabs[0].key : "0");
  const add = () => {
    handleAddTab();
  };

  const remove = (targetKey) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;
    tabs.forEach((tab, i) => {
      if (tab.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newTabs = tabs.filter((tab) => tab.key !== targetKey);
    if (newTabs.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newTabs[lastIndex].key.toString();
      } else {
        newActiveKey = newTabs[0].key.toString();
      }
    }
    setTabs(newTabs);
    setActiveKey(newActiveKey);
    updateSheetsParent(newTabs);
  };
  const onEdit = (targetKey, action) => {
    if (action === "add") {
      add();
    } else {
      remove(targetKey);
    }
  };

  return (
    <>
      <div>
        <MDBox py={2}>
          <Tabs
            type="editable-card"
            onChange={(key) => setActiveKey(key)}
            activeKey={activeKey}
            onEdit={onEdit}
            items={tabs.map((tab, tabIndex) => {
              return {
                label: tab.tabName,
                key: tab.key,
                children: (
                  <React.Fragment key={tab.key}>
                    <TabNameSection
                      tab={tab}
                      tabIndex={tabIndex}
                      tabs={tabs}
                      inputVisibility={inputVisibility}
                      handleTabNameChange={handleTabNameChange}
                      handleToggleInputSection={handleToggleInputSection}
                      handleRemoveTab={handleRemoveTab}
                      handleMoveTabUp={handleMoveTabUp}
                      handleMoveTabDown={handleMoveTabDown}
                    />
                    {tab.inputs.map((input, inputIndex) => (
                      <InputsSection
                        key={inputIndex}
                        tab={tab}
                        input={input}
                        tabIndex={tabIndex}
                        inputIndex={inputIndex}
                        handleAddInput={handleAddInput}
                        handleInputChange={handleInputChange}
                        handleTypeChange={handleTypeChange}
                        handleAddOption={handleAddOption}
                        handleRemoveInput={handleRemoveInput}
                        handleMoveInputUp={handleMoveInputUp}
                        handleMoveInputDown={handleMoveInputDown}
                      />
                    ))}
                  </React.Fragment>
                ),
              };
            })}
          />
        </MDBox>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <MDButton
          variant="gradient"
          color="info"
          onClick={() => {
            handleSubmit();
          }}
        >
          Suivant
        </MDButton>
      </div>
    </>
  );
}

Project.propTypes = {
  id: PropTypes.string,
  sheets: PropTypes.array,
  updateSheetsParent: PropTypes.func,
  setTabValue: PropTypes.func,
  setProjectData: PropTypes.func,
  projectData: PropTypes.object,
};
