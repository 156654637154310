import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import cookies from "react-cookies";

const ProtectedRoute = () => {
  const cookiesToken = cookies.load("token");

  return cookiesToken ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
