import axios from "axios";
import { baseUrlApi } from "proxy";
import cookies from "react-cookies";
import CAxios from "./CAxios";

/** Login user */
export const login = async (email, password) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const { data } = await axios.post(
      `${baseUrlApi}/authenticate`,
      {
        email,
        password,
      },
      config
    );
    if (data.success) {
      cookies.save("token", data.success.token);
      cookies.save("nomComplet", data.success.nomComplet);
      return true;
    }

    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const toAddUser = async (user) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(`${baseUrlApi}/user/add`, user, config);
    return data.success;
  } catch (error) {
    console.error(error);
  }
};

export const toGetAllUsers = async () => {
  try {
    const { data } = await CAxios.post(`/api/user/list`);
    return data.success.users ?? [];
  } catch (error) {
    console.error(error);
  }
};

export const toDeleteUser = async (id) => {
  try {
    const { data } = await CAxios.post(`/api/user/delete`, { id });
    return data.success.remove;
  } catch (error) {
    console.error(error);
  }
};

export const toUpdatePassword = async (password, key) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      `${baseUrlApi}/password/update`,
      {
        password,
        key,
      },
      config
    );
    return data.success.data.type;
  } catch (error) {
    console.error(error);
  }
};

/****Project API's */

export const toAddProject = async (projectData) => {
  try {
    const { data } = await CAxios.post(`/api/project/add`, projectData);
    return data.success.project;
  } catch (error) {
    console.error(error);
  }
};

export const toGetListOfProject = async () => {
  const { data } = await CAxios.post("/api/project/list");
  return data.success.projects ?? [];
};

export const toGetListOfProjectDetails = async ({ id }) => {
  const { data } = await CAxios.post("/api/project/detail", { id });
  return data.success.project;
};

export const toUpdateProject = async ({ id, data }) => {
  try {
    const response = await CAxios.post(`/api/project/update`, { id, data });
    return response.data.success.project;
  } catch (error) {
    console.error(error);
  }
};
