import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Descriptions, Select, Space, DatePicker, Spin } from "antd";
import MDTypography from "components/MDTypography";
import { Box, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";
import dayjs from "dayjs";

import isBetween from "dayjs/plugin/isBetween";

import "dayjs/locale/fr";
import locale from "antd/es/date-picker/locale/fr_FR";
import { LoadingOutlined } from "@ant-design/icons";
import MetaData from "layouts/MetaData/MetaData";

const { Option } = Select;
const { RangePicker } = DatePicker;
dayjs.locale("fr");
dayjs.extend(isBetween);

const InfoGenerale = ({ data, loading }) => {
  const [selectedDepense, setSelectedDepense] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [chartData, setChartData] = useState({ labels: [], datasets: {} });

  const depenses = data ? data?.finance?.depenses : [];

  const uniqueDepenses = [...new Set(depenses?.map((item) => item.tiers))];

  // set selected depense
  const handleDepenseChange = (value) => {
    setSelectedDepense(value);
  };

  // set date range
  const handleDateRangeChange = (dates) => {
    const formattedDates = dates
      ? [dayjs(dates[0]).format("YYYY-MMM-DD"), dayjs(dates[1]).format("YYYY-MMM-DD")]
      : [null, null];
    setDateRange(formattedDates);
  };

  // filter and set chart data
  const filterAndSetChartData = () => {
    const [startDate, endDate] = dateRange;
    const filteredData = depenses?.filter((item) => {
      const isSelected = selectedDepense?.includes(item.tiers);
      const itemDate = item.date;
      const isInDateRange =
        (!startDate || itemDate >= startDate) && (!endDate || itemDate <= endDate);
      return isSelected && isInDateRange;
    });

    const aggregatedData = filteredData?.reduce((acc, item) => {
      const total = parseFloat(item.total);
      if (acc[item.ligne]) {
        acc[item.ligne] += total;
      } else {
        acc[item.ligne] = total;
      }
      return acc;
    }, {});

    const labels = aggregatedData && Object.keys(aggregatedData);
    const totals = aggregatedData && Object.values(aggregatedData);

    setChartData({
      labels,
      datasets: {
        label: "Total Dépenses",
        data: totals,
      },
    });
  };

  // filter and set chart data
  useEffect(() => {
    filterAndSetChartData();
  }, [selectedDepense, dateRange]);

  const { sales, tasks } = reportsLineChartData;

  return (
    <>
      {loading ? (
        <Spin
          indicator={<LoadingOutlined spin />}
          size="large"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        />
      ) : (
        <>
          <div
            style={{
              marginTop: 20,
              background: "#fff",
              padding: 15,
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            }}
          >
            <MetaData title="Fidel - Info Générale" />

            <Descriptions
              bordered
              column={{
                xs: 1,
                sm: 2,
                md: 3,
                lg: 3,
                xl: 3,
                xxl: 3,
              }}
            >
              <Descriptions.Item
                label={
                  <Box display="flex" alignItems="center">
                    <Icon fontSize="small">folder_copy</Icon>
                    <span style={{ marginLeft: 5 }}>Nom de projet</span>
                  </Box>
                }
              >
                <MDTypography fontWeight="medium" fontSize="1rem" color="dark">
                  {data?.projectName}
                </MDTypography>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Box display="flex" alignItems="center">
                    <Icon fontSize="small">supervisor_account</Icon>
                    <span style={{ marginLeft: 5 }}>Nom de bailleur</span>
                  </Box>
                }
              >
                <MDTypography fontWeight="medium" fontSize="1rem" color="dark">
                  {data?.nomDeBailleur}
                </MDTypography>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Box display="flex" alignItems="center">
                    <Icon fontSize="small">calendar_month</Icon>
                    <span style={{ marginLeft: 5 }}>Date de début</span>
                  </Box>
                }
              >
                <MDTypography fontWeight="medium" fontSize="1rem" color="dark">
                  {data?.dateStart}
                </MDTypography>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Box display="flex" alignItems="center">
                    <Icon fontSize="small">calendar_month</Icon>
                    <span style={{ marginLeft: 5 }}>Date de fin</span>
                  </Box>
                }
              >
                <MDTypography fontWeight="medium" fontSize="1rem" color="dark">
                  {data?.dateEnd}
                </MDTypography>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Box display="flex" alignItems="center">
                    <Icon fontSize="small">business_center</Icon>
                    <span style={{ marginLeft: 5 }}>Responsable</span>
                  </Box>
                }
              >
                <MDTypography fontWeight="medium" fontSize="1rem" color="dark">
                  {data?.responsables?.map((el, key) => (
                    <div key={key}>{el}</div>
                  ))}
                </MDTypography>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Box display="flex" alignItems="center">
                    <Icon fontSize="small">text_snippet</Icon>
                    <span style={{ marginLeft: 5 }}>Notes</span>
                  </Box>
                }
              >
                <MDTypography fontWeight="semibold" fontSize="1rem" color="dark">
                  {data?.notes}
                </MDTypography>
              </Descriptions.Item>
            </Descriptions>
          </div>
          <hr />
          <Box
            mt={5}
            p={2}
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", backgroundColor: "#fff" }}
          >
            <Select
              mode="multiple"
              allowClear
              dropdownStyle={{ zIndex: 9999999 }}
              placeholder="Séléctionner une ou plus de options"
              maxTagTextLength={5}
              style={{ width: "30%" }}
              onChange={handleDepenseChange}
            >
              {uniqueDepenses &&
                uniqueDepenses.map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
            </Select>
            <Space direction="vertical" size={12} style={{ marginLeft: 10 }}>
              <RangePicker
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                defaultValue={dayjs(new Date())}
                locale={locale}
                format="YYYY-MMM-DD"
                onChange={handleDateRangeChange}
              />
            </Space>
          </Box>
          <Box mt={7}>
            <MDBox>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox mb={3}>
                    <ReportsBarChart
                      color="info"
                      title="Présence"
                      description="Présence des clients"
                      date="24-mai-2024"
                      chart={chartData}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox mb={3}>
                    <ReportsLineChart
                      color="warning"
                      title="daily sales"
                      description={
                        <>
                          (<strong>+15%</strong>) increase in today sales.
                        </>
                      }
                      date="updated 4 min ago"
                      chart={sales}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox mb={3}>
                    <DefaultDoughnutChart
                      icon={{ color: "warning" }}
                      title="sales"
                      description="Last Campaign Performance"
                      date="just updated"
                      chart={sales}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Box>
        </>
      )}
    </>
  );
};

InfoGenerale.propTypes = {
  projectId: PropTypes.string,
  data: PropTypes.object,
  loading: PropTypes.bool,
};

export default InfoGenerale;
