/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import TableScreen from "components/MDTable/Table";
import { toAddUser } from "utils/apis";
import { Popconfirm, message } from "antd";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { toGetAllUsers } from "utils/apis";
import { toDeleteUser } from "utils/apis";
import { Icon, IconButton } from "@mui/material";
import EditUser from "./EditUser";
import MetaData from "layouts/MetaData/MetaData";

function User() {
  const handleAddUser = async (newUser) => {
    const success = await toAddUser(newUser);
    if (success) {
      message.success("Utilisateur ajouté avec succès");
      fetchUsers();
    } else {
      message.error("Échec d'ajouter l'utilisateur. Veuillez réessayer");
    }
  };

  const [users, setUsers] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    setLoading(true);
    toGetAllUsers().then((res) => {
      setLoading(false);
      setUsers(res);
    });
  };

  const handleDelete = async (id) => {
    try {
      const response = await toDeleteUser(id);
      if (response) {
        message.success("Utilisateur supprimé avec succès");
      } else {
        message.error("Échec de la suppression de l'utilisateur. Veuillez réessayer");
      }
      const updatedUsers = users.filter((user) => user.id !== id);
      setUsers(updatedUsers);
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
    }
  };

  const [editUser, setEditUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleEdit = (user) => {
    setEditUser(user);
    setIsModalOpen(true);
  };

  // const handleEditUser = async (updatedUser) => {
  //   try {
  //     const success = await toUpdateUser(updatedUser);
  //     if (success) {
  //       message.success("Utilisateur modifié avec succès");
  //       fetchUsers();
  //     } else {
  //       message.error("Échec de la modification de l'utilisateur. Veuillez réessayer");
  //     }
  //   } catch (error) {
  //     console.error("There was a problem with your fetch operation:", error);
  //   } finally {
  //     setIsModalOpen(false);
  //   }
  // };

  const Author = ({ name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  return (
    <DashboardLayout>
      <MetaData title="Fidel - Liste des utilisateurs" />

      <DashboardNavbar />

      <TableScreen
        columns={[
          { Header: "Nom et Prénom", accessor: "author", width: "30%", align: "left" },
          { Header: "Email", accessor: "employed", align: "left" },
          { Header: "Num Téléphone", accessor: "telephone", align: "left" },
          { Header: "Poste", accessor: "role", align: "left" },
          { Header: "Action", accessor: "action", align: "left" },
        ]}
        rows={users.map((user) => ({
          author: <Author name={`${user.firstname} ${user.lastname}`} />,
          telephone: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {user.titre}
            </MDTypography>
          ),
          role: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {user.roles
                .map((role) => {
                  return role;
                })
                .join(", ")}
            </MDTypography>
          ),
          employed: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {user.email}
            </MDTypography>
          ),
          action: (
            <>
              {/* <IconButton
                onClick={() => {
                  handleEdit(user);
                }}
              >
                <Icon
                  sx={{
                    cursor: "pointer",
                    marginRight: "10px",
                    color: "orange",
                    fontSize: "17px !important",
                  }}
                >
                  border_color_rounded
                </Icon>
              </IconButton> */}
              <Popconfirm
                title="Suppression"
                description="Voulez-vous vraiment supprimer cet utilisateur?"
                onConfirm={() => handleDelete(user.id)}
                okText="Oui"
                cancelText="Non"
              >
                <IconButton>
                  <Icon sx={{ cursor: "pointer", color: "#FF3333", fontSize: "18px !important" }}>
                    delete_sweep
                  </Icon>
                </IconButton>
              </Popconfirm>
              {isModalOpen && (
                <EditUser
                  handleEditUser={handleEditUser}
                  data={editUser}
                  visible={isModalOpen}
                  handleClose={() => setIsModalOpen(false)}
                />
              )}
            </>
          ),
        }))}
        // users={users}
        title="Liste des utilisateurs"
        handleAddUser={handleAddUser}
      />

      <Footer />
    </DashboardLayout>
  );
}

export default User;
