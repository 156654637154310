import { AppBar, Box, Grid, Icon, Modal, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import breakpoints from "assets/theme/base/breakpoints";
import { DatePicker, Form, Input, Select } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import locale from "antd/es/date-picker/locale/fr_FR";
import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import Project from "./Project";
import { formItemStyle } from "components/styles";
import MDButton from "components/MDButton";
import Budget from "layouts/Budget/Budget";
import { toGetAllUsers } from "utils/apis";

dayjs.locale("fr");

export default function ProjectModal({ open, handleClose, project, updateProject }) {
  const style = {
    position: "absolute",
    minHeight: "80vh",
    height: "80vh",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxHeight: "80vh",
    overflowY: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const [projectData, setProjectData] = useState({ data: { ...project } });

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const [form] = Form.useForm();

  const updateSheets = (list) => {
    project.sheets = list;
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      // setProjects(projectData);
      setTabValue(1);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const handleChange = (field, value) => {
    setProjectData((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [field]: value,
      },
    }));
  };

  const onChangeDate = (date, dateString) => {
    const parsedDate = dayjs(dateString, "YYYY-MM-DD");
    if (parsedDate.isValid()) {
      setProjectData((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          dateStart: dateString,
        },
      }));
    } else {
      console.error("Invalid date object received.");
    }
  };
  const onChangeDateEnd = (date, dateString) => {
    const parsedDate = dayjs(dateString, "YYYY-MM-DD");
    if (parsedDate.isValid()) {
      setProjectData((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          dateEnd: dateString,
        },
      }));
    } else {
      console.error("Invalid date object received.");
    }
  };

  const [users, setUsers] = useState([]);
  const fetchUsers = () => {
    toGetAllUsers().then((res) => {
      setUsers(res);
    });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Modal
      title="Ajouter un projet"
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <AppBar position="static">
          <Tabs
            indicatorColor="primary"
            orientation={tabsOrientation}
            value={tabValue}
            onChange={handleSetTabValue}
          >
            <Tab
              disabled
              label="Informations sur le projet"
              icon={
                <Icon fontSize="small" sx={{ mt: -0.25 }}>
                  information
                </Icon>
              }
            />
            <Tab
              disabled
              label="Sheets"
              icon={
                <Icon fontSize="small" sx={{ mt: -0.25 }}>
                  table_view
                </Icon>
              }
            />
            <Tab
              disabled
              label="Budget"
              icon={
                <Icon fontSize="small" sx={{ mt: -0.25 }}>
                  currency_bitcoin_outlined
                </Icon>
              }
            />
          </Tabs>
        </AppBar>
        {tabValue === 0 ? (
          <div>
            <form style={{ marginTop: "20px" }}>
              <Form size={"large"} layout={"vertical"} form={form}>
                <MDBox py={2}>
                  <Grid
                    container
                    spacing={3}
                    py={1}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid item xs={4}>
                      <Form.Item
                        rules={[{ required: true, message: "Champ obligatoire" }]}
                        style={formItemStyle}
                        label="Nom du projet"
                        name={"projectName"}
                        onChange={(e) => handleChange("projectName", e.target.value)}
                      >
                        <Input style={{ fontSize: "16px" }} />
                      </Form.Item>
                    </Grid>
                    <Grid item xs={4}>
                      <Form.Item
                        style={formItemStyle}
                        rules={[{ required: true, message: "Champ obligatoire" }]}
                        label="Date du début du projet"
                        name={"dateStart"}
                      >
                        <DatePicker
                          getPopupContainer={(triggerNode) => {
                            return triggerNode.parentNode;
                          }}
                          locale={locale}
                          defaultValue={dayjs(new Date())}
                          dropdownStyle={{ zIndex: 9999999 }}
                          style={{ width: "100%", zIndex: 9999999 }}
                          format="YYYY-MM-DD"
                          placeholder="Date de début"
                          onChange={onChangeDate}
                        />
                      </Form.Item>
                    </Grid>

                    <Grid item xs={4}>
                      <Form.Item
                        style={formItemStyle}
                        rules={[{ required: true, message: "Champ obligatoire" }]}
                        label="Date de fin du projet"
                        name={"dateEnd"}
                      >
                        <DatePicker
                          getPopupContainer={(triggerNode) => {
                            return triggerNode.parentNode;
                          }}
                          locale={locale}
                          defaultValue={dayjs(new Date())}
                          dropdownStyle={{ zIndex: 9999999 }}
                          style={{ width: "100%", zIndex: 9999999 }}
                          format="YYYY-MM-DD"
                          placeholder="Date de fin"
                          onChange={onChangeDateEnd}
                        />
                      </Form.Item>
                    </Grid>

                    <Grid item xs={4}>
                      <Form.Item
                        style={formItemStyle}
                        label="Responsable du projet"
                        name={"responsable"}
                        rules={[{ required: true, message: "Champ obligatoire" }]}
                      >
                        <Select
                          mode="multiple"
                          style={{ width: "100%" }}
                          label="Responsable projet"
                          dropdownStyle={{ zIndex: 9999999 }}
                          onChange={(value) => handleChange("responsable", value)}
                        >
                          {users.map((el, key) => (
                            <Select.Option key={key} value={el.id}>
                              {el.firstname + " " + el.lastname}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Grid>
                    <Grid item xs={4}>
                      <Form.Item
                        rules={[{ required: true, message: "Champ obligatoire" }]}
                        style={formItemStyle}
                        label="Nom de bailleur"
                        name={"nomDeBailleur"}
                        onChange={(e) => handleChange("nomDeBailleur", e.target.value)}
                      >
                        <Input style={{ fontSize: "16px" }} />
                      </Form.Item>
                    </Grid>
                    <Grid item xs={4}>
                      <Form.Item style={formItemStyle} label="Notes" name={"notes"}>
                        <Input.TextArea
                          style={{ fontSize: "16px" }}
                          onChange={(e) => handleChange("notes", e.target.value)}
                        />
                      </Form.Item>
                    </Grid>
                  </Grid>
                </MDBox>
              </Form>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "8rem",
                }}
              >
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Suivant
                </MDButton>
              </div>
            </form>
          </div>
        ) : tabValue === 1 ? (
          <>
            <Project
              id={projectData.id}
              updateSheetsParent={(list) => updateSheets(list)}
              sheets={project.sheets}
              setProjectData={setProjectData}
              projectData={projectData}
              setTabValue={setTabValue}
            />
          </>
        ) : tabValue === 2 ? (
          <>
            <Budget
              handleClose={handleClose}
              setProjectData={setProjectData}
              projectData={projectData}
              updateProject={updateProject}
            />
          </>
        ) : (
          <></>
        )}
      </Box>
    </Modal>
  );
}

ProjectModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  project: PropTypes.object,
  updateProject: PropTypes.func,
};
