import { message } from "antd";
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import TabNameSection from "../TabNameSection";
import InputsSection from "../InputsSection";
import PropTypes from "prop-types";
import { toGetListOfProjectDetails } from "utils/apis";

const EditTabs = ({ tableKey, setEditTabs, projectId }) => {
  const [inputVisibility, setInputVisibility] = useState([false]);

  useEffect(() => {
    const fetchData = async () => {
      const project = await toGetListOfProjectDetails({ id: projectId });
      if (project.sheets && project.sheets.length > 0) {
        const filteredSheet = project.sheets.filter((sheet) => sheet.key === tableKey);
        if (filteredSheet.length > 0) {
          setTabs(filteredSheet);
          setEditTabs(filteredSheet);
          setInputVisibility(filteredSheet.map(() => true));
        } else {
          setEditTabs([
            {
              tabName: "",
              key: 0,
              inputs: [
                {
                  type: "",
                  label: "",
                  options: [],
                  active: "",
                  inactive: "",
                },
              ],
            },
          ]);
          setInputVisibility([false]);
        }
      }
    };

    fetchData();
  }, [tableKey]);

  /* Updated initial state */
  const [tabs, setTabs] = useState([
    {
      tabName: "",
      key: 0,
      inputs: [
        {
          type: "",
          label: "",
          options: [],
          active: "",
          inactive: "",
        },
      ],
    },
  ]);

  /* Add tab name and validate it */

  const handleToggleInputSection = (tabIndex) => {
    setInputVisibility((prev) => {
      const newVisibility = [...prev];
      newVisibility[tabIndex] = !newVisibility[tabIndex];
      return newVisibility;
    });
  };

  const handleTabNameChange = (tabIndex, newName) => {
    const newTabs = [...tabs];
    newTabs[tabIndex].tabName = newName;
    setTabs(newTabs);
  };

  /* Remove tabs */
  const handleRemoveTab = (tabIndex) => {
    const newTabs = tabs.filter((tab, index) => index !== tabIndex);
    const newInputVisibility = inputVisibility.filter((_, index) => index !== tabIndex);
    setTabs(newTabs);
    setInputVisibility(newInputVisibility);
  };

  /* Add input and validate it */
  const handleAddInput = (tabIndex) => {
    const newTabs = [...tabs];
    const lastInput = newTabs[tabIndex].inputs[newTabs[tabIndex].inputs.length - 1];
    if (
      lastInput?.label === "" ||
      lastInput.type === "" ||
      lastInput?.options?.length === 0 ||
      lastInput?.active === "" ||
      lastInput?.inactive === ""
    ) {
      message.warning("Veuillez d'abord remplir les champs");
      return;
    }
    if (lastInput?.options?.length === 1) {
      message.warning("Veuillez ajouter la deuxième option.");
      return;
    }
    const newInput = { type: "", label: "", options: [], active: "", inactive: "" };
    newTabs[tabIndex].inputs.push(newInput);
    setTabs(newTabs);
  };

  /* Add options to select */
  const handleAddOption = (tabIndex, inputIndex, value) => {
    const newTabs = [...tabs];
    if (Array.isArray(value)) {
      newTabs[tabIndex].inputs[inputIndex].options = value;
    } else {
      newTabs[tabIndex].inputs[inputIndex].options.push(value);
    }
    setTabs(newTabs);
  };

  /* Change input type and validate */
  const handleTypeChange = (tabIndex, inputIndex, value) => {
    const newTabs = [...tabs];
    newTabs[tabIndex].inputs[inputIndex].type = value;
    if (["text", "number", "date", "datetime"].includes(value)) {
      newTabs[tabIndex].inputs[inputIndex] = {
        type: value,
        label: newTabs[tabIndex].inputs[inputIndex].label,
      };
    } else if (value === "select") {
      newTabs[tabIndex].inputs[inputIndex] = {
        type: "select",
        label: newTabs[tabIndex].inputs[inputIndex].label,
        options: [],
      };
    } else if (value === "radio") {
      newTabs[tabIndex].inputs[inputIndex] = {
        type: "radio",
        label: newTabs[tabIndex].inputs[inputIndex].label,
        active: "",
        inactive: "",
      };
    }

    setTabs(newTabs);
  };

  const handleInputChange = (tabIndex, inputIndex, field, value) => {
    const newTabs = [...tabs];
    if (field === "label") {
      newTabs[tabIndex].inputs[inputIndex].label = value;
    } else if (field === "active") {
      newTabs[tabIndex].inputs[inputIndex].active = value;
    } else if (field === "inactive") {
      newTabs[tabIndex].inputs[inputIndex].inactive = value;
    }
    setTabs(newTabs);
  };

  /* Remove input */
  const handleRemoveInput = (tabIndex, inputIndex) => {
    const newTabs = [...tabs];

    /* if there is only one input, do nothing */
    if (newTabs[tabIndex].inputs.length === 1) {
      return;
    }

    newTabs[tabIndex].inputs.splice(inputIndex, 1);
    setTabs(newTabs);
  };

  /* Move tab up */
  const handleMoveTabUp = (tabIndex) => {
    if (tabIndex === 0) return;
    const newTabs = [...tabs];
    const temp = newTabs[tabIndex];
    newTabs[tabIndex] = newTabs[tabIndex - 1];
    newTabs[tabIndex - 1] = temp;
    setTabs(newTabs);
  };

  /* Move tab down */
  const handleMoveTabDown = (tabIndex) => {
    if (tabIndex === tabs.length - 1) return;
    const newTabs = [...tabs];
    const temp = newTabs[tabIndex];
    newTabs[tabIndex] = newTabs[tabIndex + 1];
    newTabs[tabIndex + 1] = temp;
    setTabs(newTabs);
  };

  /* Move input up */
  const handleMoveInputUp = (tabIndex, inputIndex) => {
    if (inputIndex === 0) return;
    const newTabs = [...tabs];
    const temp = newTabs[tabIndex].inputs[inputIndex];
    newTabs[tabIndex].inputs[inputIndex] = newTabs[tabIndex].inputs[inputIndex - 1];
    newTabs[tabIndex].inputs[inputIndex - 1] = temp;
    setTabs(newTabs);
  };

  /* Move input down */
  const handleMoveInputDown = (tabIndex, inputIndex) => {
    if (inputIndex === tabs[tabIndex].inputs.length - 1) return;
    const newTabs = [...tabs];
    const temp = newTabs[tabIndex].inputs[inputIndex];
    newTabs[tabIndex].inputs[inputIndex] = newTabs[tabIndex].inputs[inputIndex + 1];
    newTabs[tabIndex].inputs[inputIndex + 1] = temp;
    setTabs(newTabs);
  };

  return (
    <div style={{ minHeight: "400px", overflow: "auto" }}>
      <form
        style={{
          marginTop: "20px",
        }}
      >
        <MDBox py={2}>
          {tabs.map((tab, tabIndex) => (
            <React.Fragment key={tab.key}>
              <TabNameSection
                tab={tab}
                tabIndex={tabIndex}
                tabs={tabs}
                inputVisibility={inputVisibility}
                handleTabNameChange={handleTabNameChange}
                handleToggleInputSection={handleToggleInputSection}
                handleRemoveTab={handleRemoveTab}
                handleMoveTabUp={handleMoveTabUp}
                handleMoveTabDown={handleMoveTabDown}
              />
              {inputVisibility[tabIndex] &&
                tab.inputs.map((input, inputIndex) => (
                  <InputsSection
                    key={inputIndex}
                    tab={tab}
                    input={input}
                    tabIndex={tabIndex}
                    inputIndex={inputIndex}
                    handleAddInput={handleAddInput}
                    handleInputChange={handleInputChange}
                    handleTypeChange={handleTypeChange}
                    handleAddOption={handleAddOption}
                    handleRemoveInput={handleRemoveInput}
                    handleMoveInputUp={handleMoveInputUp}
                    handleMoveInputDown={handleMoveInputDown}
                  />
                ))}
            </React.Fragment>
          ))}
        </MDBox>
      </form>
    </div>
  );
};

export default EditTabs;

EditTabs.propTypes = {
  tableKey: PropTypes.number,
  setEditTabs: PropTypes.func,
  projectId: PropTypes.string,
};
