import axios from "axios";
import cookie from "react-cookies";

// Custom axios
const CAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:8000",
  validateStatus: (status) => status < 400,
});

CAxios.interceptors.request.use((config) => {
  let { url, headers } = config;

  if (url?.startsWith("/api/") && cookie.load("token") != null) {
    headers["Authorization"] = "Bearer " + cookie.load("token");
  }
  return config;
});

CAxios.interceptors.response.use(null, function (error) {
  if (error.response && error.response.status === 401) {
    cookie.remove("token");
    window.location.replace("/");
  }
  if (error.response && error.response.status === 303) {
    console.error("Erreur", "cette entité existe déjà");
  }

  if (error.response && error.response.status === 500) {
    console.error("Erreur", "Quelque chose s'est mal passé! Veuillez contacter l'administrateur");
  }
  return Promise.reject(error);
});

export default CAxios;
