import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import PropTypes from "prop-types";
import TableAntd from "components/AntdTable/TableAntd";
import { toGetListOfProjectDetails } from "utils/apis";
import MetaData from "layouts/MetaData/MetaData";

const { TabPane } = Tabs;

const MDTabs = ({ projectId }) => {
  const [items, setItems] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const projects = await toGetListOfProjectDetails({ id: projectId });
      if (projects) {
        setItems(projects);
      }
    };

    fetchData();
  }, [projectId]);

  return (
    <>
      <MetaData title="Fidel - Sheets" />

      <Tabs type="card" style={{ margin: "1vh" }}>
        {items &&
          items.sheets.map((item) => (
            <TabPane tab={item.tabName} key={item.key}>
              <TableAntd data={item.inputs} tableKey={item.key} projectId={projectId} />
            </TabPane>
          ))}
      </Tabs>
    </>
  );
};

MDTabs.propTypes = {
  projectId: PropTypes.string,
};

export default MDTabs;
