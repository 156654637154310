import { Layout, Button, Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import cookie from "react-cookies";
import { useParams } from "react-router-dom";
import ReactPasswordChecklist from "react-password-checklist";
import { recaptchaKey } from "proxy";
import logo from "../../../assets/images/brand.png";
import BasicLayout from "../components/BasicLayout";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { toUpdatePassword } from "utils/apis";

const defaultTheme = createTheme();

const UpdatePassword = () => {
  const { key } = useParams();

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [captchaActive, setCaptchaActive] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  useEffect(() => {
    cookie.remove("token", { path: "/" });
  }, []);
  const layout = {
    labelCol: { span: 0 },
    wrapperCol: { span: 24 },
  };

  const submit = async () => {
    await toUpdatePassword(password, key).then((res) => {
      switch (res) {
        case -1:
          message.error("Erreur", "Clé non trouvée");
          break;
        case 0:
          message.error(
            "Erreur",
            "Votre lien est expirée, merci de demander un autre depuis votre administrateur"
          );
          break;
        case 1:
          message.success("Votre mot de passe a été mis à jour avec succès");
          navigate("/");

          break;

        default:
          break;
      }
    });
  };

  return (
    <BasicLayout>
      <ThemeProvider theme={defaultTheme}>
        <Layout style={{ minHeight: "100vh", backgroundColor: "#fff" }}>
          <Layout.Content
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Form
              className="jin--form-login"
              {...layout}
              name="basic"
              initialValues={{
                remember: true,
                username: "",
                password: "",
              }}
              form={form}
              onFinish={submit}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={logo} width={80} alt="Fidel-rose" />
                <h4 style={{ marginLeft: "10px" }}>Fidel Metouia</h4>
              </div>

              <div
                style={{
                  marginTop: "3vh",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Form.Item
                  label=""
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Veuillez taper votre mot de passe!",
                    },
                  ]}
                >
                  <Input.Password
                    onChange={(e) => setPassword(e.target.value)}
                    className="jin--input"
                    placeholder="Mot de passe"
                  />
                </Form.Item>

                <Form.Item
                  label=""
                  name="password2"
                  rules={[
                    {
                      required: true,
                      message: "Veuillez retaper votre mot de passe!",
                    },
                  ]}
                >
                  <Input.Password
                    onChange={(e) => setPasswordAgain(e.target.value)}
                    className="jin--input"
                    placeholder="Retapez votre mot de passe"
                  />
                </Form.Item>
                <Form.Item className="jin--form-item-login">
                  <ReCAPTCHA
                    sitekey={recaptchaKey}
                    onChange={(c) => (c ? setCaptchaActive(true) : setCaptchaActive(false))}
                  />
                </Form.Item>
                <ReactPasswordChecklist
                  rules={["minLength", "number", "capital", "match"]}
                  minLength={6}
                  value={password}
                  className="jin--checklist-text"
                  valueAgain={passwordAgain}
                  onChange={(isValid) => {}}
                  messages={{
                    minLength: "Le mot de passe comporte au moins 6 caractères.",
                    number: "Le mot de passe comporte un numéro.",
                    capital: "Le mot de passe a une lettre majuscule.",
                    match: "Mots de passe identitiques.",
                  }}
                />

                <Form.Item className="jin--form-item-login">
                  <Button
                    className="jin--btn  jin--btn-primary"
                    htmlType="submit"
                    disabled={password !== passwordAgain || !captchaActive}
                  >
                    Valider
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Layout.Content>
          <Layout.Footer
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="jin--text">By Fidel Metouia</span>
          </Layout.Footer>
        </Layout>
      </ThemeProvider>
    </BasicLayout>
  );
};

export default UpdatePassword;
