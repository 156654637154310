import React from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import { Card, Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import MetaData from "layouts/MetaData/MetaData";

const BudgetDetails = ({ data, loading }) => {
  return (
    <>
      {loading ? (
        <Spin
          indicator={<LoadingOutlined spin />}
          size="large"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        />
      ) : (
        <>
          <MetaData title="Fidel - Budget" />

          <MDBox mt={2} pt={4} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={2}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <MDTypography variant="h6" color="white">
                      Budget
                    </MDTypography>
                  </MDBox>

                  <div
                    style={{
                      backgroundColor: "white",
                      marginTop: "20px",
                    }}
                  >
                    <table style={{ borderCollapse: "collapse", width: "100%" }}>
                      <thead>
                        <tr style={{ background: "white" }}>
                          {data &&
                            data.finance.headers.map((header, index) => (
                              <th
                                key={index}
                                style={{
                                  padding: "8px",
                                  border: "1px solid #EEECE8",
                                  fontSize: "18px",
                                }}
                              >
                                {header}
                              </th>
                            ))}
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data.finance.budget.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              {data &&
                                data.finance.headers.map((header, colIndex) => (
                                  <td
                                    key={colIndex}
                                    style={{
                                      padding: "8px",
                                      border: "1px solid #EEECE8",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {row[header]}
                                  </td>
                                ))}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </>
      )}
    </>
  );
};

BudgetDetails.propTypes = {
  projectId: PropTypes.string,
  data: PropTypes.object,
  loading: PropTypes.bool,
};

export default BudgetDetails;
