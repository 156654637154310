import { Grid, Icon } from "@mui/material";
import { Input } from "antd";
import MDButton from "components/MDButton";
import React from "react";
import PropTypes from "prop-types";

const TabNameSection = ({
  tab,
  tabIndex,
  tabs,
  inputVisibility,
  handleTabNameChange,
  handleToggleInputSection,
  handleRemoveTab,
  handleMoveTabUp,
  handleMoveTabDown,
}) => {
  return (
    <Grid container spacing={3} py={1}>
      <Grid item xs={5}>
        <Input
          placeholder="Enterer le nom du sheet"
          style={{ width: "80%" }}
          type="text"
          value={tab.tabName}
          onChange={(e) => handleTabNameChange(tabIndex, e.target.value)}
        />
      </Grid>
      <Grid item xs={0.5}>
        <MDButton
          iconOnly={true}
          variant="contained"
          color="info"
          onClick={() => handleToggleInputSection(tabIndex)}
          disabled={!tab.tabName.trim()}
        >
          {inputVisibility[tabIndex] ? <Icon>visibility</Icon> : <Icon>visibility_off</Icon>}
        </MDButton>
      </Grid>
      <Grid item xs={1}>
        <MDButton
          iconOnly={true}
          variant="contained"
          color="error"
          onClick={() => handleRemoveTab(tabIndex)}
        >
          <Icon>delete_outline_outlined</Icon>
        </MDButton>
      </Grid>

      <Grid item xs={2} style={{ width: "20%" }}>
        <MDButton
          iconOnly={true}
          variant="contained"
          color="warning"
          onClick={() => handleMoveTabUp(tabIndex)}
          disabled={tabIndex === 0}
        >
          <Icon>north</Icon>
        </MDButton>

        <MDButton
          sx={{ ml: "10px" }}
          iconOnly={true}
          variant="contained"
          color="warning"
          onClick={() => handleMoveTabDown(tabIndex)}
          disabled={tabIndex === tabs.length - 1}
        >
          <Icon>south</Icon>
        </MDButton>
      </Grid>
    </Grid>
  );
};

export default TabNameSection;

TabNameSection.propTypes = {
  tab: PropTypes.object,
  tabIndex: PropTypes.number,
  tabs: PropTypes.array,
  inputVisibility: PropTypes.array,
  handleTabNameChange: PropTypes.func,
  handleToggleInputSection: PropTypes.func,
  handleRemoveTab: PropTypes.func,
  handleMoveTabUp: PropTypes.func,
  handleMoveTabDown: PropTypes.func,
};
