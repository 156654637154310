import React, { useEffect, useState } from "react";
import { DatePicker, Input, Select, Table, message } from "antd";
import PropTypes from "prop-types";
import MDButton from "components/MDButton";
import { Icon, Button, Box } from "@mui/material";
import { Radio } from "antd";
import BasicModal from "components/MDModal/Modal";
import EditTabs from "layouts/Projects/EditTabs/EditTabs";
import MDTypography from "components/MDTypography";
import { toGetListOfProjectDetails, toUpdateProject } from "utils/apis";

const { Option } = Select;

const TableAntd = ({ data, tableKey, projectId }) => {
  const [rows, setRows] = useState([{}]);
  const [editTabs, setEditTabs] = useState([{}]);
  const [existingData, setExistingData] = useState([]);
  const [isEditing, setIsEditing] = useState(true);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const project = await toGetListOfProjectDetails({ id: projectId });
      if (project) {
        const sheetData = project.sheets.find((sheet) => sheet.key === tableKey);
        if (sheetData && sheetData.data.length > 0) {
          setExistingData(sheetData.data);
          setIsEditing(false);
        }
      }
      setLoading(false);
    };
    fetchData();
  }, [projectId, tableKey]);

  const handleAddRow = () => {
    setRows([...rows, {}]);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
  };

  const handleInputChange = (value, rowIndex, dataIndex, label) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex][label] = value;
    setRows(updatedRows);
  };

  const renderCell = (item, record, rowIndex) => {
    switch (item.type) {
      case "number":
        return (
          <Input
            type="number"
            min={0}
            max={100}
            value={record[item.value]}
            onChange={(e) => handleInputChange(e.target.value, rowIndex, item.value, item.label)}
            placeholder={`Enter ${item.label}`}
          />
        );
      case "select":
        return (
          <Select
            style={{ minWidth: 150, width: "100%" }}
            onChange={(value) => handleInputChange(value, rowIndex, item.value, item.label)}
            placeholder={`Select ${item.label}`}
          >
            {item.options.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        );
      case "radio":
        return (
          <Radio.Group
            onChange={(e) => handleInputChange(e.target.value, rowIndex, item.value, item.label)}
          >
            <Radio value={item.active}>{item.active}</Radio>
            <Radio value={item.inactive}>{item.inactive}</Radio>
          </Radio.Group>
        );
      case "date":
        return (
          <DatePicker
            onChange={(date, dateString) =>
              handleInputChange(dateString, rowIndex, item.value, item.label)
            }
          />
        );

      case "datetime":
        return (
          <DatePicker.TimePicker
            onChange={(date, dateString) =>
              handleInputChange(dateString, rowIndex, item.value, item.label)
            }
          />
        );

      default:
        return (
          <Input
            value={record[item.value]}
            onChange={(e) => handleInputChange(e.target.value, rowIndex, item.value, item.label)}
            placeholder={`Enter ${item.label}`}
          />
        );
    }
  };

  const columns = data.map((item) => ({
    title: item.label,
    dataIndex: tableKey,
    key: tableKey,
    render: (text, record, index) => renderCell(item, record, index),
  }));

  columns &&
    columns.push({
      title: "Actions",
      key: "actions",
      width: "3%",
      render: (_, record, index) => (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <MDButton
            sx={{ mr: 1 }}
            iconOnly={true}
            variant="contained"
            color="dark"
            onClick={handleAddRow}
          >
            <Icon>queue_outlined</Icon>
          </MDButton>
          <MDButton
            iconOnly={true}
            variant="contained"
            color="error"
            onClick={() => handleDeleteRow(index)}
          >
            <Icon>delete_outline_outlined</Icon>
          </MDButton>
        </Box>
      ),
    });

  const dataSource = rows.map((row) => ({
    key: tableKey,
    ...row,
  }));

  const handleEdit = async () => {
    try {
      const existingProjectData = await toGetListOfProjectDetails({ id: projectId });
      const project = existingProjectData;
      const sheetIndex = project.sheets.findIndex((sheet) => sheet.key === tableKey);

      if (sheetIndex !== -1) {
        const sheet = project.sheets[sheetIndex];
        const correspondingTab = editTabs.find((tab) => tab.key === sheet.key);

        if (correspondingTab) {
          Object.assign(sheet, correspondingTab);
        } else {
          console.error(`Corresponding tab for sheet with key ${tableKey} not found`);
        }

        project.sheets[sheetIndex] = sheet;

        const success = await toUpdateProject({ id: projectId, data: project });

        if (success) {
          message.success("Project updated successfully");
        } else {
          message.error("Failed to update project");
        }
      }
      setOpen(false);
    } catch (error) {
      console.error("Error updating project:", error);
    }
  };

  const addValues = async () => {
    const existingProjectData = await toGetListOfProjectDetails({ id: projectId });

    const project = existingProjectData;
    const sheet = project.sheets.find((sheet) => sheet.key === tableKey);

    dataSource.forEach((item) => {
      sheet.data.push(item);
    });

    const success = await toUpdateProject({ id: projectId, data: project });

    if (success) {
      message.success("Ligne ajoutée avec succès");
    } else {
      message.error("Echec de l'ajout de ligne");
    }

    setExistingData(sheet.data);
    setIsEditing(false);
  };

  return (
    <>
      <>
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <Button>
            {existingData && existingData.length > 0 && (
              <MDButton
                variant="contained"
                color="warning"
                size="small"
                onClick={() => setIsEditing(true)}
              >
                <Icon>add_circle_outline</Icon>
                <MDTypography
                  variant="button"
                  fontWeight="medium"
                  fontSize="small"
                  color="white"
                  ml={1}
                  component="h2"
                >
                  Nouvelle ligne
                </MDTypography>
              </MDButton>
            )}
          </Button>
          <BasicModal
            titleBtn={
              <MDButton variant="contained" color="info" size="small">
                <Icon>mode_edit</Icon>
                <MDTypography
                  variant="button"
                  fontWeight="medium"
                  fontSize="small"
                  color="white"
                  ml={1}
                >
                  Modifier Tabs
                </MDTypography>
              </MDButton>
            }
            titleModal="Modifier Tabs"
            btn="Modifier"
            open={open}
            handleClose={handleClose}
            handleOpen={handleOpen}
            handleSubmit={handleEdit}
          >
            <EditTabs tableKey={tableKey} setEditTabs={setEditTabs} projectId={projectId} />
          </BasicModal>
        </div>

        {isEditing ? (
          <>
            <Table columns={columns} dataSource={dataSource} pagination={false} loading={loading} />
            <Box display="flex" justifyContent="flex-end" mt="1%">
              <MDButton
                variant="contained"
                size="small"
                color="info"
                onClick={addValues}
                sx={{ mr: 1 }}
              >
                Valider
              </MDButton>
              {existingData && existingData.length > 0 && (
                <MDButton
                  variant="contained"
                  size="small"
                  color="error"
                  onClick={() => setIsEditing(false)}
                >
                  Annuler
                </MDButton>
              )}
            </Box>
          </>
        ) : (
          <>
            <Table
              columns={data.map((item) => ({
                title: item.label,
                dataIndex: item.label,
                key: item.label,
              }))}
              dataSource={existingData}
              pagination={false}
            />
          </>
        )}
      </>
    </>
  );
};

export default TableAntd;

TableAntd.propTypes = {
  projectId: PropTypes.string,
  tableKey: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.object),
};
