import dayjs from "dayjs";

export default class ProjectModel {
  id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  projectName = "nouveau projet";
  nomDeBailleur = "";
  dateStart = dayjs(new Date()).format("YYYY-MMM-DD");
  dateEnd = dayjs(new Date()).format("YYYY-MMM-DD");
  status = "new";
  responsableId = -1;
  notes = "here";
  sheets = [
    {
      key: String(0),
      tabName: "Nouveau sheet",
      inputs: [
        {
          type: "",
          label: "",
          options: [],
          active: "",
          inactive: "",
        },
      ],
      data: [],
    },
  ];
  finance = {
    depenses: [],
    budget: [],
    headers: [],
  };
}
