import { Checkbox, FormControlLabel, Grid, Icon, IconButton } from "@mui/material";
import { Form, Input } from "antd";
import BasicModal from "components/MDModal/Modal";
import MDTypography from "components/MDTypography";
import React, { useState } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import { formItemStyle } from "components/styles";

const AddUser = ({ handleAddUser }) => {
  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    telephone: "",
    title: "mohamed",
    roles: [],
    access: [
      {
        project: {
          lire: true,
          ecrire: true,
          modifier: true,
        },
        projects: {
          lire: true,
          ecrire: true,
          supprimer: true,
          modifier: true,
        },
        user: {
          lire: true,
          ecrire: true,
          supprimer: true,
          modifier: true,
        },
        droit: {
          lire: true,
          ecrire: true,
          supprimer: true,
          modifier: true,
        },
      },
    ],
  });

  const [form] = Form.useForm();

  const handlePermissionChange = (event, category) => {
    const { name, checked } = event.target;
    setUser((prevUser) => ({
      ...prevUser,
      [category]: {
        ...prevUser[category],
        [name]: checked,
      },
    }));
  };

  const handleRoleChange = (event) => {
    setUser({ ...user, roles: [event.target.value] });
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    try {
      await form.validateFields();

      handleAddUser(user);
      setUser({
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        telephone: "",
        title: "",
        roles: [],
        access: [
          {
            project: {
              lire: true,
              ecrire: true,
              modifier: true,
            },
            projects: {
              lire: true,
              ecrire: true,
              supprimer: true,
              modifier: true,
            },
            user: {
              lire: true,
              ecrire: true,
              supprimer: true,
              modifier: true,
            },
            droit: {
              lire: true,
              ecrire: true,
              supprimer: true,
              modifier: true,
            },
          },
        ],
      });

      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  // const permission = JSON.parse(localStorage.getItem("user")) || {};
  return (
    <>
      <BasicModal
        titleBtn={
          <IconButton color={"white"}>
            <Icon fontSize="large">add_circle_outline</Icon>
          </IconButton>
        }
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
        titleModal="Ajouter un utilisateur"
        btn="Ajouter"
        handleSubmit={handleSubmit}
        // permission={getRole(permission, "user", "ecrire")}
      >
        <div>
          <form style={{ marginTop: "20px" }}>
            <Form size={"large"} layout={"vertical"} form={form}>
              <MDBox py={2}>
                <Grid
                  container
                  spacing={3}
                  py={1}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Grid item xs={4}>
                    <Form.Item
                      rules={[{ required: true, message: "Champ obligatoire" }]}
                      style={formItemStyle}
                      label="Nom"
                      name={"nom"}
                      value={user.nom}
                      onChange={(e) => setUser({ ...user, firstname: e.target.value })}
                    >
                      <Input type="text" style={{ fontSize: "16px" }} />
                    </Form.Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Form.Item
                      rules={[{ required: true, message: "Champ obligatoire" }]}
                      style={formItemStyle}
                      label="Prénom"
                      name={"prenom"}
                      value={user.prenom}
                      onChange={(e) => setUser({ ...user, lastname: e.target.value })}
                    >
                      <Input type="text" style={{ fontSize: "16px" }} />
                    </Form.Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Form.Item
                      rules={[{ required: true, message: "Champ obligatoire" }]}
                      style={formItemStyle}
                      label="Email"
                      name={"email"}
                      value={user.email}
                      onChange={(e) => setUser({ ...user, email: e.target.value })}
                    >
                      <Input type="email" style={{ fontSize: "16px" }} />
                    </Form.Item>
                  </Grid>
                  {/* <Grid item xs={4}>
                    <Form.Item
                      rules={[{ required: true, message: "Champ obligatoire" }]}
                      style={formItemStyle}
                      label="Mot de passe"
                      name={"password"}
                      value={user.password}
                      onChange={(e) => setUser({ ...user, password: e.target.value })}
                    >
                      <Input type="password" style={{ fontSize: "16px" }} />
                    </Form.Item>
                  </Grid> */}
                  <Grid item xs={4}>
                    <Form.Item
                      rules={[{ required: true, message: "Champ obligatoire" }]}
                      style={formItemStyle}
                      label="Téléphone"
                      name={"telephone"}
                      value={user.telephone}
                      onChange={(e) => setUser({ ...user, telephone: e.target.value })}
                    >
                      <Input type="text" style={{ fontSize: "16px" }} />
                    </Form.Item>
                  </Grid>
                  <Grid item xs={5}>
                    <Form.Item
                      rules={[{ required: true, message: "Champ obligatoire" }]}
                      style={formItemStyle}
                      label="Poste"
                      name={"poste"}
                      value={user.roles}
                      onChange={handleRoleChange}
                    >
                      <Input type="text" style={{ fontSize: "16px" }} />
                    </Form.Item>
                  </Grid>
                  <Grid item xs={4}>
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                      Permissions Project
                    </MDTypography>

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={user?.access[0]?.project?.lire}
                          onChange={(e) => handlePermissionChange(e, "project")}
                          name="lire"
                        />
                      }
                      label="Lire"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={user?.access[0]?.project?.ecrire}
                          onChange={(e) => handlePermissionChange(e, "project")}
                          name="ecrire"
                        />
                      }
                      label="Ecrire"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={user?.access[0]?.project?.modifier}
                          onChange={(e) => handlePermissionChange(e, "project")}
                          name="modifier"
                        />
                      }
                      label="Modifier"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MDTypography
                      variant="caption"
                      color="text"
                      fontWeight="medium"
                      sx={{ gridColumn: "span 4" }}
                    >
                      Permissions user
                    </MDTypography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={user?.access[0]?.user?.lire}
                          onChange={(e) => handlePermissionChange(e, "user")}
                          name="lire"
                        />
                      }
                      label="Lire"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={user?.access[0]?.user?.ecrire}
                          onChange={(e) => handlePermissionChange(e, "user")}
                          name="ecrire"
                        />
                      }
                      label="Ecrire"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={user?.access[0]?.user?.supprimer}
                          onChange={(e) => handlePermissionChange(e, "user")}
                          name="désactiver"
                        />
                      }
                      label="Désactiver"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={user?.access[0]?.user?.modifier}
                          onChange={(e) => handlePermissionChange(e, "user")}
                          name="modifier"
                        />
                      }
                      label="Modifier"
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Form>
          </form>
        </div>
      </BasicModal>
    </>
  );
};

AddUser.propTypes = {
  handleAddUser: PropTypes.array,
};

export default AddUser;
