import { Box } from "@mui/material";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import React from "react";

const StatusIndicators = () => {
  return (
    <Box display="flex" justifyContent="center" mt={2} mb={4}>
      <MDBox ml={-1} mx={2}>
        <MDBadge badgeContent="En cours" color="info" variant="gradient" size="lg" />
      </MDBox>
      <MDBox ml={-1} mx={2}>
        <MDBadge badgeContent="Fini" color="success" variant="gradient" size="lg" />
      </MDBox>
      <MDBox ml={-1} mx={2}>
        <MDBadge badgeContent="Dépassement de délai" color="error" variant="gradient" size="lg" />
      </MDBox>
      <MDBox ml={-1} mx={2}>
        <MDBadge badgeContent="Futur" color="warning" variant="gradient" size="lg" />
      </MDBox>
    </Box>
  );
};

export default StatusIndicators;
