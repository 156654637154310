import { Grid, Icon } from "@mui/material";
import { Input, Select } from "antd";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React from "react";
import PropTypes from "prop-types";

const { Option } = Select;

const InputsSection = ({
  input,
  tab,
  tabIndex,
  inputIndex,
  handleAddInput,
  handleInputChange,
  handleTypeChange,
  handleAddOption,
  handleRemoveInput,
  handleMoveInputUp,
  handleMoveInputDown,
}) => {
  return (
    <Grid
      container
      spacing={3}
      py={1}
      key={inputIndex}
      style={{ display: "flex", alignItems: "center" }}
    >
      <Grid item xs={3}>
        <Input
          placeholder="Label"
          width={"100%"}
          type="text"
          value={input.label}
          onChange={(e) => handleInputChange(tabIndex, inputIndex, "label", e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <Select
          dropdownStyle={{ zIndex: 9999999 }}
          style={{ width: "100%" }}
          value={input.type}
          onChange={(value) => {
            handleTypeChange(tabIndex, inputIndex, value);
          }}
        >
          <Option value="">Selectionnez un type</Option>
          <Option value="text">Text</Option>
          <Option value="select">Select</Option>
          <Option value="number">Number</Option>
          <Option value="radio">Radio</Option>
          <Option value="date">Date</Option>
          <Option value="datetime">Date Time</Option>
        </Select>
      </Grid>
      <Grid item xs={3}>
        <Select
          mode="tags"
          style={{
            width: "100%",
            display: input.type === "select" ? "block" : "none",
          }}
          value={input.options}
          placeholder=""
          onChange={(value) => handleAddOption(tabIndex, inputIndex, value)}
        />
        <MDBox
          display="flex"
          width="100%"
          style={{ display: input.type === "radio" ? "block" : "none" }}
        >
          <Input
            style={{ width: "45%" }}
            placeholder="Enterer la valeur active"
            value={input.active}
            onChange={(e) => handleInputChange(tabIndex, inputIndex, "active", e.target.value)}
          />
          <Input
            style={{ width: "45%", marginLeft: "10px" }}
            placeholder="Enter la valeur inactive"
            value={input.inactive}
            onChange={(e) => handleInputChange(tabIndex, inputIndex, "inactive", e.target.value)}
          />
        </MDBox>
      </Grid>
      <Grid item xs={1} style={{ display: "flex" }}>
        <MDButton
          variant="contained"
          color="success"
          iconOnly={true}
          onClick={(e) => {
            handleAddInput(tabIndex);
            e.preventDefault();
          }}
        >
          <Icon style={{ color: "white" }}>add_circle_outlined</Icon>
        </MDButton>
        <MDButton
          style={{ marginLeft: "10px" }}
          variant="contained"
          color="error"
          iconOnly={true}
          onClick={(e) => {
            handleRemoveInput(tabIndex, inputIndex);
            e.preventDefault();
          }}
        >
          <Icon>delete_outline_outlined</Icon>
        </MDButton>
      </Grid>
      <Grid item xs={1} style={{ display: "flex" }}>
        <MDButton
          style={{ marginRight: "3px" }}
          iconOnly={true}
          variant="contained"
          color="warning"
          onClick={() => handleMoveInputUp(tabIndex, inputIndex)}
          disabled={inputIndex === 0}
        >
          <Icon>north</Icon>
        </MDButton>

        <MDButton
          iconOnly={true}
          variant="contained"
          color="warning"
          onClick={() => handleMoveInputDown(tabIndex, inputIndex)}
          disabled={inputIndex === tab.inputs.length - 1}
        >
          <Icon>south</Icon>
        </MDButton>
      </Grid>
    </Grid>
  );
};

export default InputsSection;

InputsSection.propTypes = {
  input: PropTypes.object,
  tabIndex: PropTypes.number,
  inputIndex: PropTypes.number,
  tab: PropTypes.object,
  handleAddInput: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleTypeChange: PropTypes.func,
  handleAddOption: PropTypes.func,
  handleRemoveInput: PropTypes.func,
  handleMoveInputUp: PropTypes.func,
  handleMoveInputDown: PropTypes.func,
};
