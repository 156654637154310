import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import { Button } from "@mui/material";

const style = {
  position: "absolute",
  minHeight: "40vh",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

// Add PropTypes validation for the titleBtn prop

export default function BasicModal({
  titleBtn,
  btn,
  children,
  handleSubmit,
  titleModal,
  permission,
  handleOpen,
  handleClose,
  open,
}) {
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      {permission !== false && <span onClick={handleOpen}>{titleBtn}</span>}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {titleModal}
          </Typography>

          {/* Add your form here */}
          {children && <Box>{children}</Box>}

          <Box display="flex" justifyContent="center" mt="20px">
            <Button
              onClick={() => {
                handleSubmit();
              }}
              type="submit"
              color="primary"
              variant="contained"
            >
              <span style={{ color: "white" }}>{btn}</span>
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

BasicModal.propTypes = {
  titleBtn: PropTypes.string.isRequired,
  btn: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  handleSubmit: PropTypes.func,
  titleModal: PropTypes.string,
  permission: PropTypes.bool,
  handleOpen: PropTypes.func,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};
