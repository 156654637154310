export const secteurs = [
  "Agriculture",
  "Artisanat",
  "Agroalimentaire",
  "Construction",
  "Services",
  "Autres",
];

export const implementation = [
  "Promoteur de Métouia",
  "Projet à Métouia",
  "Projet et promoteur à Métouia",
  "Autres",
];

export const niveau = [
  "Néant",
  "Primaire",
  "Secondaire",
  "Universitaire",
  "Formation Professionnelle",
];

export const adress = [
  "Métouia",
  "Ouedhref",
  "Mida",
  "Akarit",
  "El Hicha",
  "Gabés",
  "Cheneni",
  "Laamarat",
  "Laaouinet",
  "Tharaa",
];

export const raisons = [
  "Contrat association",
  "Contrat de travail",
  "Numéro injoinable",
  "Contrat CIVP",
  "Participation pour la prochaine phase du projet",
  "Besoin d'une formation SPE",
  "Bénéficier par la loi de recrutement des chomeurs qui ont dépassé 10 ans",
  "Encore dans le projet",
];

export const bénéficiaire = ["Phase 1", "Phase 2", "Deux phases", "Nouveau/Nouvelle"];

export const sexe = ["Homme", "Femme"];

export const age = ["[18-24]", "[25-30]", "[31-35]", "[35-40]", "Autres"];

export const situation = ["Célibataire", "Marié(e)", "Divorcé(e)", "Veuf(ve)"];

export const typeQuartier = ["Urbain", "Péri-urbain", "Rural"];

export const stade = ["Stade avancé", "Early stage"];
